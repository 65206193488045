import { t } from '@lingui/macro';
import { LoadingScreen } from 'noddi-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchAddons from '../../../../../../hooks/ApiHooks/useFetchAddons';
import { TierHotelList } from '../../../../../BookingFlow/Steps/WheelStorage';
import { useStepCompletionValidation } from '../../../../../BookingFlow/hooks/useStepCompletionValidation';
import ExpandBookingScreen from '../../ExpandBookingScreen';
import useExpandBookingPaths from '../../hooks/useExpandBookingPaths';

export const TireHotel = () => {
  const { isWheelStorageStepValid } = useStepCompletionValidation();

  const navigate = useNavigate();
  const { refetch: fetchAddons } = useFetchAddons();
  const { addonsPath, summaryPath } = useExpandBookingPaths();

  const [isAddonsLoading, setIsAddonsLoading] = useState(false);

  const navigateToNextStep = async () => {
    const { data: addons } = await fetchAddons();

    const nextRoute = addons?.length ? addonsPath : summaryPath;

    return navigate(nextRoute);
  };

  async function onNextClicked() {
    setIsAddonsLoading(true);
    await navigateToNextStep();
    setIsAddonsLoading(false);
  }

  return (
    <ExpandBookingScreen
      onNextClicked={onNextClicked}
      disableNextButton={!isWheelStorageStepValid}
      title={t`Where are your wheels stored?`}
    >
      {isAddonsLoading ? <LoadingScreen /> : <TierHotelList />}
    </ExpandBookingScreen>
  );
};
