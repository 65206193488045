import { Trans, plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  CouponCard,
  LoadingScreen,
  NoddiBasicCard,
  NoddiButton,
  NoddiDialog,
  NoddiExternalLink,
  NoddiIcon,
  colors
} from 'noddi-ui';
import { useState } from 'react';

import getCommonTranslations from '../../commonTranslations';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { REFERRAL_PROGRAM_INFO } from '../../constants/externalUrls';
import CopyReferralLink from '../Referrals/CopyReferralLink';
import ReferredUsers from '../Referrals/ReferredUsers';
import NoCoupons from './NoCoupons';

const Bonus = () => {
  const { currentUserGroupId } = useAuthContext();

  const [showReferredUsers, setShowReferredUsers] = useState(false);

  const {
    data: coupons,
    isPending: isCouponsPending,
    error: couponsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: { id: currentUserGroupId as number, includeUsedOnBookingIds: [] }
  });

  const {
    isPending: isReferralPending,
    data: referral,
    error: referralError
  } = noddiAsync.useGet({
    type: URLKeys.getReferrals,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  if (isCouponsPending || isReferralPending) {
    return <LoadingScreen />;
  }
  if (couponsError) {
    return <ErrorPageWithTranslations apiError={couponsError} />;
  }
  if (referralError) {
    return <ErrorPageWithTranslations apiError={referralError} />;
  }

  const friendsText = plural(referral.referrals.length, {
    one: 'friend',
    other: 'friends'
  });
  return (
    <ContentWrapper showNewBookingButton className='relative' title={t`Bonus`}>
      <div className='flex flex-col gap-3 text-primary-darkPurple'>
        <div className='flex flex-col gap-3 md:flex-row'>
          <CopyReferralLink referralCode={referral.referralCode} />
          <NoddiBasicCard className='flex w-full flex-col justify-center'>
            <p className='font-bold text-5'>
              <Trans>How it works</Trans>
            </p>
            <div className='flex items-center justify-between'>
              <ul className='w-full list-disc pl-5'>
                <li>
                  <Trans>Invite one or more friends</Trans>
                </li>
                <li>
                  <Trans>You both get up to 300 kroner discount</Trans>
                </li>

                <NoddiExternalLink
                  startIcon='ArrowSquareRightCorner'
                  className='-ml-5.5 flex gap-2 p-0 sm:hidden'
                  size='small'
                  href={REFERRAL_PROGRAM_INFO}
                >
                  <Trans>Read more</Trans>
                </NoddiExternalLink>
              </ul>
              <NoddiExternalLink
                endIcon='ArrowRight'
                className='hidden sm:flex'
                size='small'
                href={REFERRAL_PROGRAM_INFO}
              >
                <Trans>Read more</Trans>
              </NoddiExternalLink>
            </div>
          </NoddiBasicCard>
        </div>
        <NoddiBasicCard className='flex w-full flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='flex flex-col gap-2 md:gap-4'>
              <p>
                <Trans>I have referred</Trans>:
              </p>
              <div className='flex items-center gap-4'>
                <NoddiIcon name='Users' size='large' color={colors.primary.darkPurple} />
                <p className='font-bold text-5'>
                  {referral.referrals.length} {friendsText}
                </p>
              </div>
            </div>
            {!!referral.referrals.length && (
              <NoddiButton variant='link' endIcon='ArrowRight' onClick={() => setShowReferredUsers(true)} size='small'>
                <Trans>Details</Trans>
              </NoddiButton>
            )}
          </div>
        </NoddiBasicCard>

        <h2 className='font-bold text-5 md:text-7'>
          <Trans>My coupons</Trans>
        </h2>

        {coupons.length ? (
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            {coupons?.map((coupon) => (
              <CouponCard
                key={coupon.id}
                namePublic={coupon.namePublic}
                descriptionPublic={coupon.descriptionPublic}
                discount={coupon.discount?.amount}
                currency={coupon.discount?.currency}
                discountPercentage={coupon.discountPercentage}
                translations={{
                  activated: getCommonTranslations().activated,
                  used: getCommonTranslations().used,
                  amount: getCommonTranslations().amount,
                  discount: getCommonTranslations().discount
                }}
              />
            ))}
          </div>
        ) : (
          <NoCoupons />
        )}

        <NoddiDialog title={t`Referred friends`} onClose={() => setShowReferredUsers(false)} open={showReferredUsers}>
          {!!referral.referrals.length && <ReferredUsers referrals={referral.referrals} />}
        </NoddiDialog>
      </div>
    </ContentWrapper>
  );
};

export default Bonus;
