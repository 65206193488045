import { TireOfferAction } from './pages/TireOffer/types';

const HOME_PATH = '/home';
const NEW_BOOKING_PATH = '/bookings/new';
const NEW_BOOKING_PATH_V2 = '/bookings/new/v2';
const TIRE_OFFER_PATH = '/tire-offer';
const EXPAND_BOOKING_PATH = `${HOME_PATH}/bookings`;

const appRoutesKeys = {
  home: 'home',
  myCarDetails: 'myCarDetails',
  myCarWheelMeasurements: 'myCarWheelMeasurements',
  myCoupons: 'myCoupons',
  myBookingDetails: 'myBookingDetails',
  expandBooking: 'expandBooking',
  expandBookingCar: 'expandBookingCar',
  expandBookingSalesItem: 'expandBookingSalesItem',
  expandBookingAddon: 'expandBookingAddon',
  expandBookingTirehotel: 'expandBookingTirehotel',
  expandBookingSummary: 'expandBookingSummary',
  login: 'login',
  confirmation: 'confirmation',
  homeError: 'homeError',
  profile: 'profile',
  editProfile: 'editProfile',
  bookings: 'bookings',
  myMembershipPrograms: 'myMembershipPrograms',
  myReferrals: 'myReferrals',
  tireStorage: 'tireStorage',
  tireStorageCancelBooking: 'tireStorageCancelBooking',
  membership: 'membership',
  paymentInfo: 'paymentInfo',
  referralsInfo: 'referralsInfo',
  campaignsInfo: 'campaignsInfo',
  bookingInfo: 'bookingInfo',
  newBooking: 'newBooking',
  newBookingAddressStep: 'newBookingAddressStep',
  newBookingCarStep: 'newBookingCarStep',
  newBookingServicesStep: 'newBookingServicesStep',
  newBookingAddonsStep: 'newBookingAddonsStep',
  newBookingWheelStorageStep: 'newBookingWheelStorageStep',
  newBookingTimeSlotStep: 'newBookingTimeSlotStep',
  newBookingSummaryStep: 'newBookingSummaryStep',
  newBookingConfirmationStep: 'newBookingConfirmationStep',
  editMyBookingTimeWindow: 'editMyBookingTimeWindow',
  homepage: 'homepage',
  companySignup: 'companySignup',
  companySignupSuccess: 'companySignupSuccess',
  tireOffer: 'tireOffer',
  tireOfferInfo: 'tireOfferInfo',
  tireOfferSelection: 'tireOfferSelection',
  tireOfferSummary: 'tireOfferSummary',
  tireOfferConfirmation: 'tireOfferConfirmation',
  newBookingV2: 'newBookingV2',
  newAddressV2: 'newAddressV2',
  newCarsV2: 'newCarsV2',
  newSalesItemsV2: 'newSalesItemsV2',
  newBookingAddonsV2: 'newBookingAddonsV2',
  newBookingTierHotelV2: 'newBookingTierHotelV2',
  newBookingTimeWindowV2: 'newBookingTimeWindowV2',
  newBookingSummaryV2: 'newBookingSummaryV2',
  multipleTireOffers: 'multipleTireOffers'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

type StringOrNumber = string | number;

// Define the parameters for the routes
interface PathConfig {
  myBookingDetails: { id: StringOrNumber };
  expandBooking: { id: StringOrNumber };
  expandBookingCar: { id: StringOrNumber };
  expandBookingSalesItem: { id: StringOrNumber };
  expandBookingAddon: { id: StringOrNumber };
  expandBookingTirehotel: { id: StringOrNumber };
  expandBookingSummary: { id: StringOrNumber };
  editMyBookingTimeWindow: { id: StringOrNumber };
  myCarDetails: { carId: StringOrNumber };
  myCarWheelMeasurements: { carId: StringOrNumber };
  paymentInfo: { slug: StringOrNumber };
  referralsInfo: { slug: StringOrNumber };
  campaignsInfo: { slug: StringOrNumber };
  bookingInfo: { slug: StringOrNumber };
  confirmation: { params: string };
  tireOfferInfo: { slug: StringOrNumber };
  tireOfferSelection: { slug: StringOrNumber };
  tireOfferSummary: { slug: StringOrNumber };
  tireOfferConfirmation: { action: TireOfferAction };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
  isBaseRouterPath?: boolean;
};
const routes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  homepage: {
    getPath: () => `/homepage`,
    getBasePath: () => '/homepage',
    isBaseRouterPath: true
  },
  home: {
    getPath: () => `${HOME_PATH}`,
    getBasePath: () => `${HOME_PATH}/*`
  },
  myBookingDetails: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}`,
    getBasePath: () => '/bookings/:id'
  },
  expandBooking: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/*`,
    getBasePath: () => '/bookings/:id/add/*'
  },
  expandBookingCar: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/car`,
    getBasePath: () => '/car'
  },
  expandBookingSalesItem: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/sales-item`,
    getBasePath: () => '/sales-item'
  },
  expandBookingAddon: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/addon`,
    getBasePath: () => '/addon'
  },
  expandBookingTirehotel: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/tire-hotel`,
    getBasePath: () => '/tire-hotel'
  },
  expandBookingSummary: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/add/summary`,
    getBasePath: () => '/summary'
  },
  editMyBookingTimeWindow: {
    getPath: ({ id }) => `${EXPAND_BOOKING_PATH}/${id}/edit`,
    getBasePath: () => '/bookings/:id/edit'
  },
  myCarDetails: {
    getPath: ({ carId }) => `${HOME_PATH}/cars/${carId}`,
    getBasePath: () => '/cars/:carId'
  },
  myCarWheelMeasurements: {
    getPath: ({ carId }) => `${HOME_PATH}/cars/${carId}/wheel-measurements`,
    getBasePath: () => '/cars/:carId/wheel-measurements'
  },
  myCoupons: {
    getPath: () => `${HOME_PATH}/coupons`,
    getBasePath: () => '/coupons',
    isBaseRouterPath: true
  },
  homeError: {
    getPath: () => `${HOME_PATH}/error`,
    getBasePath: () => '/error'
  },
  profile: {
    getPath: () => `${HOME_PATH}/profile`,
    getBasePath: () => '/profile',
    isBaseRouterPath: true
  },
  editProfile: {
    getPath: () => `${HOME_PATH}/profile/edit`,
    getBasePath: () => '/profile/edit'
  },
  myMembershipPrograms: {
    getPath: () => `${HOME_PATH}/membership-programs`,
    getBasePath: () => '/membership-programs',
    isBaseRouterPath: true
  },
  bookings: {
    getPath: () => `${HOME_PATH}/bookings`,
    getBasePath: () => '/bookings',
    isBaseRouterPath: true
  },
  myReferrals: {
    getPath: () => `${HOME_PATH}/referrals`,
    getBasePath: () => '/referrals',
    isBaseRouterPath: true
  },
  confirmation: {
    getPath: ({ params }) => `/confirmation?${params}`,
    getBasePath: () => '/confirmation',
    isBaseRouterPath: true
  },
  tireStorage: {
    getPath: () => `${HOME_PATH}/tire-storage`,
    getBasePath: () => '/tire-storage',
    isBaseRouterPath: true
  },
  tireStorageCancelBooking: {
    getPath: () => `${HOME_PATH}/tire-storage/booking/cancel`,
    getBasePath: () => '/booking/cancel'
  },
  membership: {
    getPath: () => `/memberships/:slug`,
    getBasePath: () => '/memberships/:slug'
  },
  paymentInfo: {
    getPath: ({ slug }) => `/b/payment/${slug}`,
    getBasePath: () => '/b/payment/:slug'
  },
  referralsInfo: {
    getPath: ({ slug }) => `/referrals/${slug}`,
    getBasePath: () => '/referrals/:slug'
  },
  campaignsInfo: {
    getPath: ({ slug }) => `/campaigns/${slug}`,
    getBasePath: () => '/campaigns/:slug'
  },
  bookingInfo: {
    getPath: ({ slug }) => `/b/${slug}`,
    getBasePath: () => '/b/:slug'
  },
  newBookingV2: {
    getPath: () => `${NEW_BOOKING_PATH_V2}/*`,
    getBasePath: () => `${NEW_BOOKING_PATH_V2}/*`
  },
  newAddressV2: {
    getPath: () => `${NEW_BOOKING_PATH_V2}/address`,
    getBasePath: () => `/address`
  },
  newCarsV2: {
    getPath: () => `${NEW_BOOKING_PATH_V2}/cars`,
    getBasePath: () => `/cars`
  },
  newSalesItemsV2: {
    getPath: () => `${NEW_BOOKING_PATH_V2}/sales-items`,
    getBasePath: () => `/sales-items`
  },
  newBookingAddonsV2: {
    getPath: () => `${NEW_BOOKING_PATH}/addons`,
    getBasePath: () => '/addons'
  },
  newBookingTierHotelV2: {
    getPath: () => `${NEW_BOOKING_PATH}/tier-hotel`,
    getBasePath: () => '/tier-hotel'
  },
  newBookingTimeWindowV2: {
    getPath: () => `${NEW_BOOKING_PATH}/time-window`,
    getBasePath: () => '/time-window'
  },
  newBookingSummaryV2: {
    getPath: () => `${NEW_BOOKING_PATH}/summary`,
    getBasePath: () => '/summary'
  },
  newBooking: {
    getPath: () => `${NEW_BOOKING_PATH}/*`,
    getBasePath: () => `${NEW_BOOKING_PATH}/*`
  },
  newBookingAddressStep: {
    getPath: () => `${NEW_BOOKING_PATH}/address`,
    getBasePath: () => '/address'
  },
  newBookingCarStep: {
    getPath: () => `${NEW_BOOKING_PATH}/car`,
    getBasePath: () => '/car'
  },
  newBookingServicesStep: {
    getPath: () => `${NEW_BOOKING_PATH}/services`,
    getBasePath: () => '/services'
  },
  newBookingAddonsStep: {
    getPath: () => `${NEW_BOOKING_PATH}/addons`,
    getBasePath: () => '/addons'
  },
  newBookingWheelStorageStep: {
    getPath: () => `${NEW_BOOKING_PATH}/wheel-storage`,
    getBasePath: () => '/wheel-storage'
  },
  newBookingTimeSlotStep: {
    getPath: () => `${NEW_BOOKING_PATH}/timeSlots`,
    getBasePath: () => '/timeSlots'
  },
  newBookingSummaryStep: {
    getPath: () => `${NEW_BOOKING_PATH}/summary`,
    getBasePath: () => '/summary'
  },
  newBookingConfirmationStep: {
    getPath: () => `${NEW_BOOKING_PATH}/confirmation`,
    getBasePath: () => '/confirmation'
  },
  companySignup: {
    getPath: () => '/company-signup',
    getBasePath: () => '/company-signup'
  },
  companySignupSuccess: {
    getPath: () => '/company-signup/success',
    getBasePath: () => '/company-signup/success'
  },
  tireOffer: {
    getPath: () => `${TIRE_OFFER_PATH}/*`,
    getBasePath: () => `${TIRE_OFFER_PATH}/*`
  },
  tireOfferInfo: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/info/${slug}`,
    getBasePath: () => `/info/:slug`
  },
  tireOfferSelection: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/select/${slug}`,
    getBasePath: () => `/select/:slug`
  },
  tireOfferSummary: {
    getPath: ({ slug }) => `${TIRE_OFFER_PATH}/summary/${slug}`,
    getBasePath: () => `/summary/:slug`
  },
  tireOfferConfirmation: {
    getPath: ({ action }) => `${TIRE_OFFER_PATH}/confirmation/${action}`,
    getBasePath: () => '/confirmation/:action'
  },
  multipleTireOffers: {
    getPath: () => `${TIRE_OFFER_PATH}/info-all`,
    getBasePath: () => '/info-all'
  }
};

export default routes;
