import { NoddiFeedbackBox } from '../../../molecules';
import { NoddiButton } from '../Buttons';

type ErrorPageBaseProps = {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
};

export const ErrorPageBase = ({ title, description, buttonText, onButtonClick }: ErrorPageBaseProps) => {
  return (
    <div className='mx-auto flex min-h-[60vh] max-w-2xl flex-col items-center justify-center p-4 text-center md:p-10'>
      <NoddiFeedbackBox variant='error' heading={title} description={description} />
      <NoddiButton onClick={onButtonClick} className='mt-6'>
        {buttonText}
      </NoddiButton>
    </div>
  );
};
