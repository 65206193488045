import { Box, SxProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { commonTexts } from '../../../commonTexts';

const CouponContainer = styled(Box)<{ $isUsed: boolean }>`
  background-color: ${(props) => (props.$isUsed ? colors.systemColors.grey : colors.primary.white)};
  padding: 20px 40px;
  border-radius: 15px;
  position: relative;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const AmountContainer = styled.div`
  border: 1px dashed #35155a;
  padding: 10px 20px;
  border-radius: 2px;
  display: flex;
  margin-top: 12px;
  width: fit-content;
`;

const IsActiveTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 0 15px 0 5px;
`;

type Props = {
  isActivated?: boolean;
  isUsed?: boolean;
  discountPercentage?: number;
  discount?: number;
  currency?: string;
  containerSx?: SxProps;
  translations?: {
    used: string;
    activated: string;
    amount: string;
    discount: string;
  };
  namePublic: string;
  descriptionPublic: string;
};

function getTag(
  isActivated: boolean,
  isUsed: boolean,
  translations: {
    used: string;
    activated: string;
  }
) {
  if (isUsed) {
    return <IsActiveTag>{translations.used}</IsActiveTag>;
  }
  if (isActivated) {
    return <IsActiveTag>{translations.activated}</IsActiveTag>;
  }
  return undefined;
}

export function CouponCard({
  discount,
  discountPercentage,
  namePublic,
  descriptionPublic,
  children,
  isActivated,
  isUsed,
  containerSx,
  translations,
  currency
}: PropsWithChildren<Props>) {
  const texts = translations ?? {
    activated: commonTexts.activated,
    used: commonTexts.used,
    amount: commonTexts.amount,
    discount: commonTexts.discount
  };
  return (
    <CouponContainer sx={containerSx} $isUsed={Boolean(isUsed)}>
      {getTag(Boolean(isActivated), Boolean(isUsed), texts)}

      <Typography variant='h5' style={{ marginTop: 12 }}>
        {namePublic}
      </Typography>
      <Typography>{descriptionPublic}</Typography>
      <div className='flex w-full justify-center'>
        <AmountContainer>
          <div className='flex gap-2'>
            <Typography variant='subtitle1' fontSize='20px' fontWeight='bold'>
              {discount ? texts.amount : texts.discount}
            </Typography>
            <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' marginY='auto'>
              {discount ? `${discount} ${currency} ` : `${discountPercentage} %`}
            </Typography>
          </div>
        </AmountContainer>
      </div>
      {children}
    </CouponContainer>
  );
}
