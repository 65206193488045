import { MembershipDiscountProps } from 'noddi-async/src/types';

import { getDiscountAmount } from '../../helper/discount';
import { getTotalPrice } from '../../helper/salesItems';
import { SelectedSalesItemFull } from '../../pages/BookingFlow/interfaces';
import { ActiveCoupon } from '../../stores/CouponStore';
import { AddShippingInfoEvent, BeginCheckoutEvent, PurchaseEvent } from './types';

export const buildEcommercePayload = (event: BeginCheckoutEvent | AddShippingInfoEvent | PurchaseEvent) => {
  const { salesItems, coupons, membershipData } = event.data;

  const windowSlotPrice = 'time' in event.data ? event.data.time.deliveryFee.amount : 0;

  const totalPrice = getTotalPrice({
    salesItems,
    coupons,
    windowSlotPrice,
    membershipData
  });

  const discounts = membershipData?.flatMap((membershipProgram) => membershipProgram.discounts) ?? [];
  const items = salesItems.map((salesItem) => toGtmDto({ salesItem, coupons, discounts }));
  const consolidatedItems = consolidateSalesItems(items);

  return {
    currency: 'NOK', // TODO: Get currency from sales items
    value: totalPrice,
    items: consolidatedItems
  };
};

type ToGtmProps = {
  salesItem: SelectedSalesItemFull;
  coupons: ActiveCoupon[];
  discounts: MembershipDiscountProps[];
};
const toGtmDto = ({ coupons, salesItem, discounts }: ToGtmProps): EventSalesItemPayload => {
  const relevantCoupons = coupons.filter((x) => x.usedOnSalesItemId === salesItem.id);
  // this SHOULD only be one
  const coupon = relevantCoupons.length > 0 ? relevantCoupons[0] : null;

  return {
    item_id: salesItem.id,
    item_name: salesItem.name,
    discount: getDiscountAmount({
      discounts,
      salesItem,
      coupon
    }),
    price: salesItem.price,
    quantity: salesItem.quantity
  };
};

type EventSalesItemPayload = {
  item_id: number;
  item_name: string;
  discount: number;
  price: number;
  quantity: number;
};

// sales items are scoped under cars, so we need to consolidate them to get the quantities right
const consolidateSalesItems = (salesItemDtos: EventSalesItemPayload[]) => {
  return salesItemDtos.reduce((acc, salesItem) => {
    const existingItem = acc.find((x) => x.item_id === salesItem.item_id);
    if (existingItem) {
      existingItem.quantity += salesItem.quantity;
    } else {
      acc.push({ ...salesItem });
    }
    return acc;
  }, [] as EventSalesItemPayload[]);
};
