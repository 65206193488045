import { Trans } from '@lingui/macro';
import { Grid, Stack, SvgIcon } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { NoddiIcon, colors } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../contexts/BookingContext';
import { getCouponDiscountAmount } from '../../../helper/discount';
import { SelectedCar, SelectedSalesItem } from '../../../pages/BookingFlow/interfaces';
import { ActiveCoupon } from '../../../stores/CouponStore';
import { getCarDisplayText } from '../../../utils/car';

export const CouponsSummary = ({ activeCoupons }: { activeCoupons: ActiveCoupon[] }) => {
  const { bookingInputData } = useBookingContext();
  return (
    <>
      <Divider sx={{ marginTop: '1.5em', marginBottom: '0.5em' }} />
      <Stack direction='row' gap='5px' marginTop='1em'>
        <SvgIcon sx={{ width: '30px', marginRight: '5px' }} color='action'>
          <NoddiIcon size='medium' name='Bill' />
        </SvgIcon>
        <Typography color='text.secondary'>
          <Trans>Coupons</Trans>
        </Typography>
      </Stack>
      {bookingInputData.selectedCars.map((car) => (
        <CouponsSummaryLine key={car.licensePlateNumber} car={car} activeCoupons={activeCoupons} />
      ))}
    </>
  );
};

const CouponsSummaryLine = ({ car, activeCoupons }: { car: SelectedCar; activeCoupons: ActiveCoupon[] }) => {
  const { salesItems } = useBookingContext();

  const salesItemsOnCar = salesItems.filter((x) => x.licensePlateNumber === car.licensePlateNumber);

  return (
    <>
      <Stack direction='row' gap='5px' marginTop='1em'>
        <NoddiIcon name='Car' />
        <Typography color='text.secondary'>{getCarDisplayText(car)}</Typography>
      </Stack>
      {salesItemsOnCar.map((salesItem) => (
        <SalesItemCouponLine key={salesItem.id} salesItem={salesItem} activeCoupons={activeCoupons} />
      ))}
    </>
  );
};

const SalesItemCouponLine = ({
  salesItem,
  activeCoupons
}: {
  salesItem: SelectedSalesItem;
  activeCoupons: ActiveCoupon[];
}) => {
  const relevantCoupons = activeCoupons.filter((x) => x.usedOnSalesItemId === salesItem.id);

  return relevantCoupons.length > 0
    ? relevantCoupons.map((coupon) => {
        return (
          // eslint-disable-next-line deprecation/deprecation
          <Grid
            key={salesItem.id}
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            marginTop='0.3em'
          >
            <span style={{ fontWeight: 'bold', maxWidth: '70%' }}>{coupon?.namePublic ?? 'NA'}</span>
            <span style={{ color: coupon ? colors.signal.success : 'initial' }}>
              {coupon ? ` -${formatCurrencyAmount(getCouponDiscountAmount(coupon, salesItem.price))}` : 'NA'}
            </span>
          </Grid>
        );
      })
    : null;
};
