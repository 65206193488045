import { Trans } from '@lingui/macro';
// eslint-disable-next-line
import { groupBy } from 'lodash';
import { AvailableSalesItem } from 'noddi-async/src/types';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { CarIdentifierWithIcon } from '../../CarIdentifierWithIcon';
import { SelectedCar } from '../../interfaces';
import {
  bookingCategoryLabelToEnum,
  getNumberOfSelectedSalesItems,
  translateBookingCategories
} from '../SalesItems/utils';
import { ServiceCategoryBlock } from '../Services';

type AddonPickerProps = {
  car: SelectedCar;
  addons: AvailableSalesItem[];
};

const AddonPicker = ({ car, addons }: AddonPickerProps) => {
  const { salesItems: selectedSalesItems } = useBookingContext();

  const bookingCategories = groupBy(addons, 'bookingCategory.slug');

  return (
    <div className='flex flex-col gap-6'>
      <CarIdentifierWithIcon car={car} />

      {Object.keys(bookingCategories)?.length > 0 ? (
        <div className='flex flex-col gap-4'>
          {Object.entries(bookingCategories).map(([category, salesItems]) => {
            const selectedItemsCount = getNumberOfSelectedSalesItems({
              selectedSalesItems,
              category,
              salesItems,
              licensePlateNumber: car.licensePlateNumber
            });

            const priceFrom = salesItems.reduce((acc, item) => Math.min(acc, item.price), Infinity);
            return (
              <ServiceCategoryBlock
                key={category}
                type={bookingCategoryLabelToEnum(category)}
                serviceName={translateBookingCategories(category)}
                priceFrom={priceFrom}
                isPopular={false}
                isAddon
                car={car}
                salesItems={salesItems}
                selectedItemsCount={selectedItemsCount}
              />
            );
          })}
        </div>
      ) : (
        <p className='mt-2'>
          <Trans> Unfortunately, we do not offer any addons for this type of car</Trans>
        </p>
      )}
    </div>
  );
};

export default AddonPicker;
