import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { NoddiIcon } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../contexts/BookingContext';
import { SelectedCar, SelectedSalesItem } from '../../../pages/BookingFlow/interfaces';

export const CarSummary = ({ car }: { car: SelectedCar }) => {
  const { salesItems } = useBookingContext();

  const salesItemsOnCar = salesItems.filter((x) => x.licensePlateNumber === car.licensePlateNumber);

  return (
    <>
      <Stack direction='row' gap='5px' marginTop='1em' alignItems='center'>
        <NoddiIcon name='Car' size='large' />
        <Typography color='text.secondary'>{car.carName}</Typography>
      </Stack>

      {salesItemsOnCar.map((salesItem) => (
        <SalesItemSummaryLine key={salesItem.id} salesItem={salesItem} />
      ))}
    </>
  );
};

const SalesItemSummaryLine = ({ salesItem }: { salesItem: SelectedSalesItem }) => {
  return (
    // eslint-disable-next-line deprecation/deprecation
    <Grid container direction='row' justifyContent='space-between' alignItems='center' marginTop='0.3em'>
      <span style={{ fontWeight: 'bold' }}>{salesItem.name}</span>
      <span> {formatCurrencyAmount(salesItem.price, 0)}</span>
    </Grid>
  );
};
