import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import TireOffer from './TireOffer';
import MultipleTireoffers from './TireOffer/MultipleTireoffers';
import TireOfferConfirmation from './TireOffer/TireOfferConfirmation';
import TireOfferSummary from './TireOffer/TireOfferSummary';
import TireSelection from './TireOffer/TireSelection';

export function TireOfferRouter() {
  return (
    <Routes>
      <Route path={routes.tireOfferInfo.getBasePath()} element={<TireOffer />} />
      <Route path={routes.tireOfferSelection.getBasePath()} element={<TireSelection />} />
      <Route path={routes.tireOfferSummary.getBasePath()} element={<TireOfferSummary />} />
      <Route path={routes.tireOfferConfirmation.getBasePath()} element={<TireOfferConfirmation />} />
      <Route path={routes.multipleTireOffers.getBasePath()} element={<MultipleTireoffers />} />
    </Routes>
  );
}
