import { plural, t } from '@lingui/macro';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';
import { useBookingContext } from '../../contexts/BookingContext';

export const SelectedCars = () => {
  const { bookingInputData } = useBookingContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const numberOfSelectedCars = bookingInputData.selectedCars.length;

  const carMessage = plural(numberOfSelectedCars, {
    one: 'Car',
    other: 'Cars'
  });

  if (numberOfSelectedCars === 0) {
    return null;
  }
  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400 }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={t`Selected cars`}
        maxWidth='md'
      >
        <div className='flex flex-col gap-2'>
          {bookingInputData.selectedCars.map((car) => (
            <span key={car.licensePlateNumber}>
              {car.carName} - {car.licensePlateNumber}
            </span>
          ))}
        </div>
      </NoddiDialog>

      <NoddiButton
        variant='secondary'
        onClick={() => {
          setIsDialogOpen(true);
        }}
        startIcon='Car'
      >
        {numberOfSelectedCars} {carMessage}
      </NoddiButton>
    </>
  );
};
