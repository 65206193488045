import { Divider, SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode, Ref, useRef } from 'react';

import { Link } from 'react-router-dom';
import { ExpandingArrow } from '../Icons';
import { TypographyOrCustom } from './TypographyOrCustom';

type KeyValueRowProps = {
  header: ReactNode;
  value?: ReactNode | null;
  onClick?: () => void;
  showDivider?: boolean;
  direction?: 'row' | 'column';
  openInNewTab?: boolean;
  to?: string;
  sx?: SxProps;
};

export const KeyValueRow = ({
  header,
  value,
  onClick,
  showDivider = true,
  to,
  openInNewTab,
  direction = 'row',
  sx = {}
}: KeyValueRowProps) => {
  const clickable = !!onClick || !!to;

  const arrowRef = useRef<HTMLDivElement | HTMLAnchorElement>(null);

  const RowContent = () => (
    <div>
      <Stack direction={direction} justifyContent='space-between' sx={{ backgroundColor: 'inherit', ...sx }}>
        <TypographyOrCustom value={header} />
        <Stack direction='row' alignItems='center'>
          {value && <TypographyOrCustom value={value} />}
          {clickable && <ExpandingArrow parentRef={arrowRef} />}
        </Stack>
      </Stack>
      {showDivider && <Divider sx={{ opacity: '1' }} />}
    </div>
  );

  if (to) {
    return (
      <Link
        className='hover:bg-systemColors-outlineStroke'
        to={to}
        target={openInNewTab ? '_blank' : '_self'}
        ref={arrowRef as Ref<HTMLAnchorElement>}
      >
        <RowContent />
      </Link>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick} className='w-full hover:bg-systemColors-outlineStroke'>
        <RowContent />
      </button>
    );
  }

  return <RowContent />;
};
