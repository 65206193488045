import { useMediaQuery } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import chunk from 'lodash/chunk';
import { URLKeys, getAllCachedDataFromUrlKey, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindowsByDateNew } from 'noddi-async/src/types';
import 'noddi-ui/src/styles/timePicker.css';
import { DateFormats, addDays, compareDates, format } from 'noddi-util';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsMobile } from '../../../hooks';
import { ApiErrorMessage } from '../../Elements/Errors';
import { LoadingScreen } from '../../Elements/Loaders';
import BookingTimeWindowPickerDate, { OnSelectTimeWindowProps } from './BookingTimeWindowPickerDate';
import { SliderArrows, usePrevNextButtons } from './SliderArrows';
import {
  getUniqueTimeSlots,
  hasAvailableSlotInNextDays,
  hasSelectedSlotInNextDays,
  parseTimeWindowData,
  sortTimeSlots
} from './utils';

interface BookingTimeWindowPickerProps extends OnSelectTimeWindowProps {
  serviceAreaId: number;
  salesItemIds: number[];
  fromDate: Date;
  selectedTimeWindowId: number | null | undefined;
  translations: {
    today: string;
    tomorrow: string;
    soldOut: string;
    nextButton: string;
    prevButton: string;
  };
}

const DAYS_TO_FETCH = 60;
export const BookingTimeWindowPicker = ({
  serviceAreaId,
  salesItemIds,
  fromDate,
  selectedTimeWindowId,
  onAvailableSelect,
  onUnavailableSelect,
  translations
}: BookingTimeWindowPickerProps) => {
  const isMobile = useIsMobile();
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const [selectedTimeWindow, setSelectedTimeWindow] = useState<{ date?: string; idx: number } | null>(null);
  const [slidesOnScreen, setSlidesOnScreen] = useState(10);
  const [isInitialScrollCheckDone, setIsInitialScrollCheckDone] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const isMediumScreen = useMediaQuery('(max-width: 960px)');
  const isSmallScreen = useMediaQuery('(max-width: 560px)');

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth;

      let visibleSlides;

      if (isSmallScreen) {
        visibleSlides = 3;
      } else if (isMediumScreen) {
        const slideSize = 0.2 * wrapperWidth;
        visibleSlides = Math.floor(wrapperWidth / slideSize);
      } else {
        visibleSlides = Math.floor(wrapperWidth / 135);
      }

      setSlidesOnScreen(visibleSlides);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef?.current?.offsetWidth]);

  const { isPending: fetchingLastTimeWindowDate, data: latestTimeWindow } = noddiAsync.useGet({
    type: URLKeys.getLatestCreatedBookingTimeWindowDate,
    queryConfig: { staleTime: Infinity }
  });

  const availableTimeWindowsInput = {
    serviceAreaId,
    salesItemIds,
    fromDate: format(fromDate, DateFormats.DASHED_DATE_ISO_8601),
    toDate: format(addDays(fromDate, DAYS_TO_FETCH), DateFormats.DASHED_DATE_ISO_8601)
  };

  const { error: timeWindowsError, isPending } = noddiAsync.useGet({
    type: URLKeys.getAvailableBookingTimeWindowsByDateNew,
    input: availableTimeWindowsInput,
    queryConfig: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchInterval: 1000 * 60 * 4 // 4 minutes
    }
  });

  const allTimeWindowsByDate = getAllCachedDataFromUrlKey<AvailableBookingTimeWindowsByDateNew>({
    urlKey: URLKeys.getAvailableBookingTimeWindowsByDateNew,
    input: availableTimeWindowsInput
  });

  const flattenAllTimeWindowsByDate = allTimeWindowsByDate.reduce((acc, obj) => ({ ...acc, ...obj }), {});

  const visibleDays = useMemo(() => {
    const dateKeys = Object.keys(flattenAllTimeWindowsByDate ?? {});

    const chunked = chunk(dateKeys, slidesOnScreen);
    return chunked[currentSlideNumber];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenAllTimeWindowsByDate, currentSlideNumber]);

  const filteredTimeWindows = useMemo(() => {
    if (!visibleDays) {
      return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return visibleDays.reduce((acc: Record<string, any>, day) => {
      if (flattenAllTimeWindowsByDate[day]) {
        acc[day] = flattenAllTimeWindowsByDate[day];
      }
      return acc;
    }, {});
  }, [flattenAllTimeWindowsByDate, visibleDays]);

  const uniqueTimeSlots = useMemo(
    () => sortTimeSlots(getUniqueTimeSlots(filteredTimeWindows)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenAllTimeWindowsByDate, visibleDays]
  );

  const parsedTimeWindowsData = useMemo(
    () =>
      parseTimeWindowData(flattenAllTimeWindowsByDate).filter((date) =>
        compareDates(date.date, latestTimeWindow, 'isBefore')
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenAllTimeWindowsByDate]
  );

  // Handle the selected time window logic
  useEffect(() => {
    if (!selectedTimeWindowId || !parsedTimeWindowsData.length) {
      return;
    }

    const selectedDateObj = parsedTimeWindowsData.find((date) =>
      date.timeWindows.some((timeWindow) => timeWindow?.id === selectedTimeWindowId)
    );

    if (selectedDateObj) {
      const timeWindowIdx = selectedDateObj.timeWindows.findIndex(
        (timeWindow) => timeWindow?.id === selectedTimeWindowId
      );
      setSelectedTimeWindow((prev) =>
        prev && prev.date === selectedDateObj.date && prev.idx === timeWindowIdx
          ? prev
          : { date: selectedDateObj.date, idx: timeWindowIdx }
      );
    }
  }, [selectedTimeWindowId, parsedTimeWindowsData]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    slidesToScroll: slidesOnScreen,
    containScroll: 'keepSnaps',
    align: 'start'
  });

  const { prevBtnDisabled, onPrevButtonClick, onNextButtonClick, nextBtnDisabled } = usePrevNextButtons(emblaApi);

  const onSlideChange = useCallback(() => {
    if (emblaApi) {
      setCurrentSlideNumber(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSlideChange);
    }
  }, [emblaApi, onSlideChange]);

  useEffect(() => {
    if (allTimeWindowsByDate.length > 0 && !isInitialScrollCheckDone && emblaApi) {
      setIsInitialScrollCheckDone(true);

      let slidesToAdd = slidesOnScreen;

      const scrollToFirstAvailableSlot = () => {
        const timeWindowsToCheck = allTimeWindowsByDate[0];

        // Recursively break if all time windows are check (no available slots)
        if (timeWindowsToCheck && slidesToAdd >= Object.keys(timeWindowsToCheck)?.length) {
          emblaApi?.scrollTo(0);

          return;
        }
        const shouldScrollNext = !hasAvailableSlotInNextDays(allTimeWindowsByDate, slidesToAdd);

        if (shouldScrollNext) {
          emblaApi?.scrollNext();
          slidesToAdd += slidesOnScreen;
          scrollToFirstAvailableSlot(); // Recursively check again
        }
      };

      const scrollToSelectedSlot = () => {
        const timeWindowsToCheck = allTimeWindowsByDate[0];

        // Recursively break if all time windows are checked (no selected slots found)
        if (timeWindowsToCheck && slidesToAdd >= Object.keys(timeWindowsToCheck)?.length) {
          emblaApi?.scrollTo(0);
          return;
        }
        const shouldScrollNext = !hasSelectedSlotInNextDays(allTimeWindowsByDate, selectedTimeWindowId, slidesToAdd);

        if (shouldScrollNext) {
          emblaApi?.scrollNext();
          slidesToAdd += slidesOnScreen;
          scrollToSelectedSlot(); // Recursively check again
        }
      };

      // If there is a selected time window, scroll to it, if not, scroll until first available slot found
      if (selectedTimeWindowId) {
        scrollToSelectedSlot();
      } else {
        scrollToFirstAvailableSlot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidesOnScreen, allTimeWindowsByDate, isInitialScrollCheckDone, emblaApi]);

  if ((isPending || fetchingLastTimeWindowDate) && allTimeWindowsByDate.length === 0) {
    return <LoadingScreen />;
  }
  if (timeWindowsError) {
    return <ApiErrorMessage error={timeWindowsError} />;
  }

  const prevDisabledClass = prevBtnDisabled && isMobile ? 'flex justify-end' : '';
  return (
    <>
      <div className={`mb-4 ${prevDisabledClass}`}>
        <SliderArrows
          translations={translations}
          isMobile={isMobile}
          slider={emblaApi}
          prevBtnDisabled={prevBtnDisabled}
          nextBtnDisabled={nextBtnDisabled}
          onPrevButtonClick={onPrevButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </div>

      <div className='z-50 flex overflow-auto overflow-x-hidden overflow-y-visible pb-3' ref={wrapperRef}>
        <div className='embla w-full overflow-x-clip' ref={emblaRef}>
          <div className='embla__container'>
            {parsedTimeWindowsData.map((timeWindowsByDate, index) => (
              <div key={index} className='embla__slide'>
                <BookingTimeWindowPickerDate
                  translations={translations}
                  selectedTimeWindowDate={selectedTimeWindow?.date}
                  selectedTimeWindowId={selectedTimeWindowId}
                  timeWindowsByDate={timeWindowsByDate}
                  onAvailableSelect={onAvailableSelect}
                  onUnavailableSelect={onUnavailableSelect}
                  isLoading={isPending}
                  uniqueTimeSlots={uniqueTimeSlots}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
