import { NoddiSelectableItem } from 'noddi-ui/src/molecules/NoddiSelectableItem';

import { RegisteredCar } from 'noddi-async/src/types';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';

type CarDropDownProps = {
  registeredCars: RegisteredCar[];
  translations: {
    add: string;
  };
};

export default function SavedCarList({ registeredCars, translations }: CarDropDownProps) {
  const mappedRegisteredCars =
    registeredCars?.map((car) => ({
      licensePlateNumber: car.licensePlateNumber,
      carName: `${car.make} ${car.model}`,
      carSizeId: car.carSizeId,
      vinNumber: car.vinNumber,
      licensePlateNumberCountryCode: car.countryCode
    })) ?? [];

  const { bookingInputData, updateBookingInputData } = useBookingContext();

  const handleChange = (selectedLicensePlate: string | undefined) => {
    const newlySelectedCarId = selectedLicensePlate;
    if (!newlySelectedCarId) {
      return;
    }

    const newSelectedCar = mappedRegisteredCars.find((car) => car.licensePlateNumber === newlySelectedCarId);

    if (!newSelectedCar) {
      return;
    }

    // don't add if already added
    const carExists = bookingInputData.selectedCars.some(
      (car) => car.licensePlateNumber === newSelectedCar.licensePlateNumber
    );

    if (carExists) {
      return;
    }

    updateBookingInputData({
      selectedCars: [
        ...bookingInputData.selectedCars,
        {
          licensePlateNumber: newSelectedCar.licensePlateNumber,
          carName: newSelectedCar.carName,
          carSizeId: newSelectedCar.carSizeId,
          vinNumber: newSelectedCar.vinNumber,
          licensePlateNumberCountryCode: newSelectedCar.licensePlateNumberCountryCode
        }
      ]
    });
    GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });
  };

  const availableCars = mappedRegisteredCars.filter(
    (car) =>
      !bookingInputData.selectedCars.some((selectedCar) => selectedCar.licensePlateNumber === car.licensePlateNumber)
  );

  return (
    <>
      {availableCars?.map((car) => {
        return (
          <NoddiSelectableItem
            title={car.carName}
            description={car.licensePlateNumber}
            iconName='Car'
            isSelected={false}
            action={() => {
              handleChange(car.licensePlateNumber);
            }}
            actionText={translations.add}
            key={car.licensePlateNumber}
          />
        );
      })}
    </>
  );
}
