import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import { colors } from '../../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../../atoms/NoddiIcon';
import { RouterLink } from '../../Navigation/RouterLink';

interface SideNavItemProps {
  active?: boolean;
  children?: ReactNode;
  depth?: number;
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  label?: ReactNode;
  open?: boolean;
  path?: string;
  onClick?: () => void;
  title: string;
}

export const SideNavItem = (props: SideNavItemProps) => {
  const { active, children, depth = 0, disabled, icon, label, open: openProp, path, title, onClick } = props;
  const [open, setOpen] = useState<boolean>(!!openProp);

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

  let startIcon: ReactNode;

  if (depth === 0) {
    startIcon = icon;
  } else {
    startIcon = (
      <Box
        sx={{
          alignItems: 'center',
          display: 'center',
          height: 20,
          justifyContent: 'center',
          width: 17
        }}
      />
    );
  }

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  // Branch

  if (children) {
    return (
      <li>
        <ButtonBase
          disabled={disabled}
          onClick={handleToggle}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pl: `${16 + offset}px`,
            pr: '16px',
            py: '6px',
            textAlign: 'left',
            width: '100%',
            ...(active && {
              ...(depth === 0 && {
                backgroundColor: 'var(--nav-item-active-bg)'
              })
            }),
            '&:hover': {
              backgroundColor: 'var(--nav-item-hover-bg)'
            }
          }}
        >
          {startIcon && (
            <Box
              component='span'
              sx={{
                alignItems: 'center',
                color: 'var(--nav-item-icon-color)',
                display: 'inline-flex',
                justifyContent: 'center',
                mr: 2,
                ...(active && {
                  color: colors.primary.orange
                })
              }}
            >
              {startIcon}
            </Box>
          )}
          <Box
            component='span'
            sx={{
              color: 'var(--nav-item-color)',
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              whiteSpace: 'nowrap',
              ...(active && {
                color: 'var(--nav-item-active-color)'
              }),
              ...(disabled && {
                color: 'var(--nav-item-disabled-color)'
              })
            }}
          >
            {title}
          </Box>
          <SvgIcon
            sx={{
              color: 'var(--nav-item-chevron-color)',
              fontSize: '14px',
              ml: 2
            }}
          >
            {open ? (
              <NoddiIcon name='AltArrowDown' color={colors.primary.white} />
            ) : (
              <NoddiIcon name='AltArrowRight' color={colors.primary.white} />
            )}
          </SvgIcon>
        </ButtonBase>
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </li>
    );
  }

  // Leaf

  const linkProps = onClick
    ? {
        onClick
      }
    : {
        component: RouterLink,
        href: path
      };

  return (
    <li>
      <ButtonBase
        disabled={disabled}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          pl: `${16 + offset}px`,
          pr: '16px',
          py: '6px',
          textAlign: 'left',
          width: '100%',
          ...(active && {
            ...(depth === 0 && {
              backgroundColor: 'var(--nav-item-active-bg)'
            })
          }),
          '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)'
          }
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component='span'
            sx={{
              alignItems: 'center',
              color: 'var(--nav-item-icon-color)',
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              ...(active && {
                color: colors.primary.white
              })
            }}
          >
            {startIcon}
          </Box>
        )}
        <Box
          component='span'
          sx={{
            color: 'var(--nav-item-color)',
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            textDecoration: () => (active && depth > 0 ? 'underline' : 'none'),
            ...(active && {
              color: 'var(--nav-item-active-color)'
            }),
            ...(disabled && {
              color: 'var(--nav-item-disabled-color)'
            })
          }}
        >
          {title}
        </Box>
        {label && (
          <Box component='span' sx={{ ml: 2 }}>
            {label} label
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};
