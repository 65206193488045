import { NoddiIcon } from 'noddi-ui';
import { SelectedCar } from './interfaces';

export const CarIdentifierWithIcon = ({ car }: { car: SelectedCar }) => {
  return (
    <h6 className='flex gap-2 font-semibold'>
      <NoddiIcon name='Car' />
      {car.carName} - {car.licensePlateNumber}
    </h6>
  );
};
