import { cva, type VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';

import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon } from '../atoms';
import { cn } from '../helpers/utils';
import { NoddiIconButton } from '../molecules/NoddiIconButton';

const chipVariants = cva('flex w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full', {
  variants: {
    variant: {
      neutral: ' border border-primary-purple  bg-primary-white text-primary-purple',
      success: ' border border-signal-success bg-signal-success50 text-secondary-black',
      destructive: ' border border-signal-danger bg-signal-danger50 text-secondary-black',
      warning: ' border  border-signal-warning bg-signal-warning50 text-secondary-black'
    },
    size: {
      default: 'h-10 px-4 py-1',
      small: 'px-2 py-0'
    }
  },
  defaultVariants: {
    variant: 'neutral',
    size: 'default'
  }
});

const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      neutral: colors.primary.purple,
      success: colors.secondary.black,
      destructive: colors.secondary.black,
      warning: colors.secondary.black
    }
  }
});

type NoddiChipProps = {
  label: ReactNode;
  onDelete?: () => void;
  icon?: IconName;
} & VariantProps<typeof chipVariants>;

export const NoddiChip = ({ label, onDelete, variant, icon, size }: NoddiChipProps) => {
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  return (
    <div className={cn(chipVariants({ variant, size }))}>
      {icon && <NoddiIcon name={icon} size='medium' color={iconColor} />}
      <span>{label}</span>
      {onDelete && (
        <NoddiIconButton
          variant='secondary'
          onClick={onDelete}
          className='border-0'
          iconName='Cross'
          iconSize='small'
        />
      )}
    </div>
  );
};
