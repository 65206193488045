import { AtLeastOnePartial, AvailableSalesItem } from 'noddi-async/src/types';
import { Dispatch, SetStateAction, createContext } from 'react';
import { IncompatibleServiceCategoryProps } from '../pages/BookingFlow/Steps/SalesItems/interface';
import { BookingInputFormData, SelectedSalesItem } from '../pages/BookingFlow/interfaces';

export const defaultBookingValues = {
  address: null,
  date: null,
  time: null,
  comment: null,
  serviceAreas: [],
  serviceAreaId: null,
  serviceCategories: [],
  selectedCars: []
};

type BookingContextType = {
  bookingInputData: BookingInputFormData;
  salesItems: SelectedSalesItem[];
  incompatibleServiceCategories: IncompatibleServiceCategoryProps[];
  setIncompatibleServiceCategories: Dispatch<SetStateAction<IncompatibleServiceCategoryProps[]>>;
  setSalesItems: Dispatch<SetStateAction<SelectedSalesItem[]>>;
  setBookingInputData: (bookingData: BookingInputFormData) => void;
  updateBookingInputData: (newValues: AtLeastOnePartial<BookingInputFormData>) => void;
  resetTimeWindows: () => void;
  resetSalesItems: () => void;
  resetBookingData: () => void;
  getAllAvailableSalesItemsOrUndefined: () => AvailableSalesItem[];
};

export const BookingContext = createContext<BookingContextType>({
  bookingInputData: defaultBookingValues,
  salesItems: [],
  incompatibleServiceCategories: [],
  setIncompatibleServiceCategories: () => undefined,
  setSalesItems: () => undefined,
  setBookingInputData: () => undefined,
  updateBookingInputData: () => undefined,
  resetTimeWindows: () => undefined,
  resetBookingData: () => undefined,
  resetSalesItems: () => undefined,
  getAllAvailableSalesItemsOrUndefined: () => []
});
