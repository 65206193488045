import { NoddiBasicCard, NoddiIconButton } from 'noddi-ui';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useBookingContext } from '../../contexts/BookingContext';

interface AnnouncementStore {
  hiddenAnnouncements: string[];
  hideAnnouncement: (announcement: string) => void;
  reset: () => void;
}

const initialStateAnnouncementStore = {
  hiddenAnnouncements: []
};

const useAnnouncementStore = create<AnnouncementStore>()(
  persist(
    (set) => ({
      ...initialStateAnnouncementStore,
      hideAnnouncement: (announcement) =>
        set((state) => ({
          hiddenAnnouncements: [...state.hiddenAnnouncements, announcement]
        })),
      reset: () => set(initialStateAnnouncementStore)
    }),
    {
      name: 'AnnouncementStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

const PublicServiceAnnouncement = () => {
  const { bookingInputData } = useBookingContext();
  const hiddenAnnouncements = useAnnouncementStore((state) => state.hiddenAnnouncements);

  const serviceAreaAnnouncements = bookingInputData.serviceAreas
    .map((serviceArea) => serviceArea.publicAnnouncement)
    .filter((x) => x && !hiddenAnnouncements.includes(x));

  return (
    <div className='mb-4 flex flex-col gap-4'>
      {serviceAreaAnnouncements.map((announcement) => (
        <Announcement key={announcement} announcement={announcement} />
      ))}
    </div>
  );
};

export default PublicServiceAnnouncement;

const Announcement = ({ announcement }: { announcement: string }) => {
  const hideAnnouncement = useAnnouncementStore((state) => state.hideAnnouncement);

  return (
    <NoddiBasicCard className='bg-signal-warning50'>
      <div className='flex justify-between gap-4'>
        <span>{announcement}</span>
        <NoddiIconButton
          iconName='Cross'
          className='-mt-2'
          variant='ghost'
          onClick={() => hideAnnouncement(announcement)}
        />
      </div>
    </NoddiBasicCard>
  );
};
