/* eslint-disable deprecation/deprecation */
import { Trans } from '@lingui/macro';
import { Divider, Grid } from '@mui/material';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

const DeliveryCostSection = ({ timeWindow }: { timeWindow: AvailableBookingTimeWindow }) => {
  return (
    <>
      <Divider sx={{ marginTop: '1.5em', marginBottom: '0.5em' }} />
      <Grid container direction='row' justifyContent='space-between' alignItems='center' marginTop='0.3em'>
        <Grid>
          <Trans>Delivery</Trans>
        </Grid>
        <Grid>{formatCurrencyAmount(timeWindow.deliveryFee.amount, 0, timeWindow.deliveryFee.currency)}</Grid>
      </Grid>
    </>
  );
};

export default DeliveryCostSection;
