import { IconName, IconSize, iconMap, sizeMap } from './types';

interface NoddiIconProps extends Pick<React.SVGProps<SVGSVGElement>, 'color' | 'className' | 'onClick'> {
  name: IconName;
  size?: IconSize;
}

export const NoddiIcon = ({ name, size = 'medium', color, className, onClick }: NoddiIconProps) => {
  const IconComponent = iconMap[name];
  const iconSize = sizeMap[size];

  return (
    <div>
      <IconComponent
        width={iconSize}
        height={iconSize}
        color={color ?? '#000000'}
        className={className}
        onClick={onClick}
      />
    </div>
  );
};
