import { plural, t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiBasicCard, NoddiButton, NoddiCircularLoader, NoddiDialog, NoddiIconButton, useIsMobile } from 'noddi-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import routes from '../../appRoutes';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import TiresSvg from '../TireOffer/TiresSvg';

interface TireSetOffersProps {
  userGroupId: number;
}

export const TireSetOffers = ({ userGroupId }: TireSetOffersProps) => {
  const isMobile = useIsMobile();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const {
    isPending: isTireSetOffersPending,
    data: tireSetOffers,
    error: tireSetOffersError
  } = noddiAsync.useGet({
    type: URLKeys.getTireSetOffersForUserGroup,
    input: { userGroupId }
  });

  const {
    mutateAsync: postRejectTireOfferQuote,
    isPending: isPostRejectTireOfferQuotePending,
    error: postRejectTireOfferQuoteError
  } = noddiAsync.usePost({
    type: URLKeys.postRejectTireOfferQuote,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getTireSetOffersForUserGroup, input: { userGroupId } });
        setDialogOpen(false);
      }
    }
  });

  if (isTireSetOffersPending) {
    return <NoddiCircularLoader />;
  }

  if (tireSetOffersError || tireSetOffers.length === 0) {
    return null;
  }

  const hasMultipleOffers = tireSetOffers.length > 1;

  function onOfferClick() {
    if (hasMultipleOffers) {
      navigate(routes.multipleTireOffers.getPath());
    } else if (tireSetOffers?.length === 1 && tireSetOffers[0]?.slug) {
      navigate(routes.tireOfferInfo.getPath({ slug: tireSetOffers[0].slug }));
    }
  }

  const tirePluralMessage = plural(tireSetOffers.length, {
    one: 'Our measurements show that your tires may soon be, or are already, below the legal tread depth.',
    other:
      'Our measurements show that you have multiple tire sets where your tires may soon be, or are already, below the legal tread depth.'
  });

  return (
    <>
      <NoddiBasicCard className='flex flex-col bg-primary-purple/30'>
        <div className='flex items-center justify-between gap-1 md:gap-4'>
          <p className='font-bold text-5 text-primary-darkPurple md:text-7'>
            <Trans>Time for new tires?</Trans>
          </p>
          <NoddiIconButton iconName='Cross' variant='ghost' onClick={() => setDialogOpen(true)} />
        </div>
        <div className='flex items-center justify-between gap-1  md:gap-4'>
          <div className='flex flex-col gap-2'>
            <p className='text-primary-darkPurple'>{tirePluralMessage}</p>
            <NoddiButton onClick={onOfferClick} endIcon='ArrowRight'>
              <Trans>See more</Trans>
            </NoddiButton>
          </div>
          <div>
            <TiresSvg height={isMobile ? 50 : 131} width={isMobile ? 111 : 192} />
          </div>
        </div>
      </NoddiBasicCard>
      <NoddiDialog
        title={t`Are you sure you want to remove this offer?`}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div className='flex flex-col gap-2'>
          <p>
            <Trans>
              We’ll remove the tire offer from your homepage. Remember, safe tires are essential for staying safe on the
              road.
            </Trans>
          </p>
          <div className='flex gap-4'>
            <NoddiButton
              loading={isPostRejectTireOfferQuotePending}
              onClick={async () =>
                Promise.all(
                  tireSetOffers.map(
                    async (offer) =>
                      await postRejectTireOfferQuote({
                        tireQuoteId: offer.id,
                        comments: 'User rejected offer from homepage'
                      })
                  )
                )
              }
              variant='destructive'
            >
              <Trans>Yes, remove the offer</Trans>
            </NoddiButton>
            <NoddiButton variant='secondary' onClick={() => setDialogOpen(false)}>
              <Trans>Close</Trans>
            </NoddiButton>
          </div>
          {postRejectTireOfferQuoteError && <ApiErrorMessageWithTrans error={postRejectTireOfferQuoteError} />}
        </div>
      </NoddiDialog>
    </>
  );
};
