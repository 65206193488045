import { PublicCoupon } from 'noddi-async/src/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type ActiveCoupon = Pick<
  PublicCoupon,
  'discount' | 'discountPercentage' | 'id' | 'validForSalesItemIds' | 'namePublic'
> & {
  usedOnSalesItemId: number;
  useOnLicensePlateNumber: string;
};

interface CouponStore {
  coupons: ActiveCoupon[];
  addCouponId: (coupon: ActiveCoupon) => void;
  deSelectCoupon: (couponId: number) => void;
  reset: () => void;
}

const initialStateCouponStore = {
  coupons: []
};

export const useCouponStore = create<CouponStore>()(
  persist(
    (set) => ({
      ...initialStateCouponStore,
      addCouponId: (coupon: ActiveCoupon) =>
        set((state) => {
          const couponExists = state.coupons.some(
            (c) => c.id === coupon.id || c.useOnLicensePlateNumber === coupon.useOnLicensePlateNumber
          );

          if (couponExists) {
            return state;
          }

          return { coupons: [...state.coupons, coupon] };
        }),
      deSelectCoupon: (couponId: number) =>
        set((state) => {
          return { coupons: state.coupons.filter((c) => c.id !== couponId) };
        }),
      reset: () => set(initialStateCouponStore)
    }),
    {
      name: 'CouponStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
