import { useNavigate } from 'react-router-dom';

import { commonTexts } from '../../../commonTexts';
import { ErrorPageBase } from './ErrorPageBase';

interface Error404PageProps {
  translations?: {
    pageNotFoundHeading: string;
    pageNotFoundMessage: string;
    goBack: string;
  };
}

const Error404Page = ({ translations }: Error404PageProps) => {
  const navigate = useNavigate();

  const { goBack, pageNotFoundHeading, pageNotFoundMessage } = translations ?? {
    pageNotFoundHeading: commonTexts.pageNotFoundHeading,
    pageNotFoundMessage: commonTexts.pageNotFoundMessage,
    goBack: commonTexts.goBack
  };

  return (
    <div className='flex h-screen justify-center'>
      <ErrorPageBase
        title={pageNotFoundHeading}
        description={pageNotFoundMessage}
        buttonText={goBack}
        onButtonClick={() => navigate(-1)}
      />
    </div>
  );
};

export { Error404Page };
