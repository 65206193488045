import { Trans, t } from '@lingui/macro';
import { Grid, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { MembershipDiscountProps, MembershipProgramProps } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../contexts/BookingContext';
import { getMembershipDiscountAmount } from '../../../helper/discount';
import { SelectedCar, SelectedSalesItem } from '../../../pages/BookingFlow/interfaces';
import { getCarDisplayText } from '../../../utils/car';

const getMemberShipDisplayNames = (memberShipPrograms: MembershipProgramProps[] | undefined) => {
  if (!memberShipPrograms) {
    return null;
  }
  return memberShipPrograms.map((membershipProgram) => membershipProgram.name).join(', ');
};

export const DiscountSummary = ({
  discounts,
  membershipData
}: {
  discounts: MembershipDiscountProps[];
  membershipData: MembershipProgramProps[] | undefined;
}) => {
  const memberShipsDisplayNames = getMemberShipDisplayNames(membershipData);
  const { bookingInputData } = useBookingContext();

  return (
    <>
      <Divider sx={{ marginTop: '1.5em', marginBottom: '0.5em' }} />
      <Stack direction='row' gap='5px' marginTop='1em' flex='row' alignItems='center'>
        <NoddiIcon name='Money' size='large' />
        <Typography color='text.secondary'>
          <Trans>Discounts</Trans>{' '}
          {memberShipsDisplayNames ? `${t`from membership with`} ${memberShipsDisplayNames}` : null}
        </Typography>
      </Stack>
      {bookingInputData.selectedCars.map((car) => (
        <DiscountSummaryLine key={car.licensePlateNumber} car={car} discounts={discounts} />
      ))}
    </>
  );
};

const DiscountSummaryLine = ({ car, discounts }: { car: SelectedCar; discounts: MembershipDiscountProps[] }) => {
  const { salesItems } = useBookingContext();

  const salesItemsOnCar = salesItems.filter((x) => x.licensePlateNumber === car.licensePlateNumber);

  return (
    <>
      <Stack direction='row' gap='5px' marginTop='1em'>
        <NoddiIcon name='Car' />
        <Typography color='text.secondary'>{getCarDisplayText(car)}</Typography>
      </Stack>
      {salesItemsOnCar.map((salesItem) => (
        <SalesItemDiscountLine key={salesItem.id} salesItem={salesItem} discounts={discounts} />
      ))}
    </>
  );
};

const SalesItemDiscountLine = ({
  salesItem,
  discounts
}: {
  salesItem: SelectedSalesItem;
  discounts: MembershipDiscountProps[];
}) => {
  const membershipDiscountAmount = getMembershipDiscountAmount(discounts, salesItem);

  //Do not show discount line if discount is 0
  if (membershipDiscountAmount === 0) {
    return null;
  }

  return (
    // eslint-disable-next-line deprecation/deprecation
    <Grid container direction='row' justifyContent='space-between' alignItems='center' marginTop='0.3em'>
      <span style={{ fontWeight: 'bold' }}>{salesItem.name}</span>
      <span style={{ color: colors.signal.success }}>-{formatCurrencyAmount(membershipDiscountAmount)}</span>
    </Grid>
  );
};
