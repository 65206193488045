import { MembershipDiscountProps } from 'noddi-async/src/types';
import { roundNumber } from 'noddi-util';

import { ActiveCoupon } from '../../../../stores/CouponStore';

export const getNewPriceForSalesItem = ({
  price,
  discount,
  coupon
}: {
  price: number;
  discount?: MembershipDiscountProps;
  coupon?: ActiveCoupon;
}) => {
  let discountAmount = 0;
  let couponAmount = 0;

  if (discount?.percentage) {
    discountAmount = (Number(discount.percentage) / 100) * price;
  } else if (discount?.price) {
    discountAmount = discount.price;
  }

  if (coupon?.discountPercentage) {
    couponAmount = (Number(coupon.discountPercentage) / 100) * price;
  } else if (coupon?.discount?.amount) {
    couponAmount = coupon?.discount.amount;
  }

  return roundNumber({
    number: price - discountAmount - couponAmount,
    decimalPlaces: 2
  });
};
