import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { SelectedCar } from '../pages/BookingFlow/interfaces';

function isCarSpec(car: SelectedCar | CarSpec): car is CarSpec {
  return 'make' in car && 'model' in car;
}

// TODO | New booking flow | deprecate this use   getCarDisplayName from noddiUI instead

export function getCarDisplayText(car: SelectedCar | CarSpec) {
  if (isCarSpec(car)) {
    return `${car.make} ${car.model} - ${car.licensePlateNumber}`;
  } else {
    return `${car.carName} - ${car.licensePlateNumber}`;
  }
}
