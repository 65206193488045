import { Route, Routes, useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import routes from '../appRoutes';
import Address from './BookingFlowV2/Steps/Address';
import { Cars } from './BookingFlowV2/Steps/Car';
import { SalesItemsV2 } from './BookingFlowV2/Steps/SalesItems';
import { useNavigateOnInvalidStep } from './BookingFlowV2/hooksV2/useNavigateOnInvalidStepV2';

export function BookingRouterV2() {
  const { navigateOnInvalidStep } = useNavigateOnInvalidStep();
  const { pathname } = useLocation();

  useEffect(() => {
    navigateOnInvalidStep();
  }, [navigateOnInvalidStep, pathname]);

  return (
    <Routes>
      <Route path={routes.newAddressV2.getBasePath()} element={<Address />} />
      <Route path={routes.newCarsV2.getBasePath()} element={<Cars />} />
      <Route path={routes.newSalesItemsV2.getBasePath()} element={<SalesItemsV2 />} />
      <Route path={routes.newBookingAddonsV2.getBasePath()} element={<SalesItemsV2 />} />
      <Route path={routes.newBookingTierHotelV2.getBasePath()} element={<SalesItemsV2 />} />
      <Route path={routes.newBookingTimeWindowV2.getBasePath()} element={<SalesItemsV2 />} />
      <Route path={routes.newBookingSummaryV2.getBasePath()} element={<SalesItemsV2 />} />
    </Routes>
  );
}
