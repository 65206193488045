// eslint-disable-next-line import/named
import { AddressOutput } from 'noddi-async/src/types';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface BookingState {
  selectedAddress?: AddressOutput;
  selectedCars: CarSpec[];
  selectedSalesItemIds: number[];
  setSelectedAddress: (address?: AddressOutput) => void;
  addCar: (carToAdd: CarSpec) => void;
  removeCar: (carToRemove: CarSpec) => void;
  clearStore: () => void;
}

const initialStateValues = {
  selectedCars: [],
  selectedSalesItemIds: [],
  selectedAddress: undefined
};

const useBookingStore = create<BookingState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      clearStore: () => set(initialStateValues),
      setSelectedAddress: (selectedAddress) => set({ selectedAddress }),
      addCar: (carToAdd) => {
        set((state) => {
          const selectedCars = state.selectedCars;
          const isCarAlreadySelected = selectedCars.some((selectedCar) => selectedCar.id === carToAdd.id);
          if (isCarAlreadySelected) {
            return state;
          }
          return { selectedCars: [...selectedCars, carToAdd] };
        });
      },
      removeCar: (carToRemove) => {
        set((state) => ({
          selectedCars: state.selectedCars.filter((selectedCar) => selectedCar.id !== carToRemove.id)
        }));
      }
    })),
    {
      name: 'booking',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useBookingCars = () => useBookingStore((state) => state.selectedCars);
export const useBookingAddress = () => useBookingStore((state) => state.selectedAddress);
export const useBookingSalesItemIds = () => useBookingStore((state) => state.selectedSalesItemIds);

// Actions
export const useBookingSetAddress = () => useBookingStore((state) => state.setSelectedAddress);
export const useBookingAddCar = () => useBookingStore((state) => state.addCar);
export const useBookingRemoveCar = () => useBookingStore((state) => state.removeCar);
export const useBookingClearStore = () => useBookingStore((state) => state.clearStore);
