import { t } from '@lingui/macro';
import { AddressOutput } from 'noddi-async/src/types';
import { NoddiBasicCard, NoddiFeedbackBox, useIsMobile, useScrollIntoView } from 'noddi-ui';
import { useState } from 'react';
import { NoServiceOfferedFormV2 } from './NoServiceOfferedFormV2';

type SelectedAddressDetailsProps = {
  address: AddressOutput;
  translations: {
    noServiceOfferedNotice: string;
  };
};

export const NoServiceOfferedV2 = ({ translations, address }: SelectedAddressDetailsProps) => {
  const isMobile = useIsMobile();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const containerRef = useScrollIntoView(!!address && isMobile);

  if (isFormSubmitted) {
    return (
      <div className='mt-4'>
        <NoddiFeedbackBox variant='success' heading={t`Thank you! We’ll Keep You Updated`} />
      </div>
    );
  }
  return (
    <div ref={containerRef} className='mt-4'>
      <NoddiBasicCard className='flex flex-col gap-4'>
        <div>{translations.noServiceOfferedNotice} 😔</div>

        <NoServiceOfferedFormV2 address={address} setIsFormSubmitted={setIsFormSubmitted} />
      </NoddiBasicCard>
    </div>
  );
};
