import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen } from 'noddi-ui';
import { useState } from 'react';

import { UseQueryResult } from '@tanstack/react-query';
import { AvailableSalesItemsForBooking, Car, NoddiAsyncError } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import useFetchAddons from '../../../../hooks/ApiHooks/useFetchAddons';
import SalesItemCar from '../../../BookingFlow/Steps/SalesItems/SalesItemCar';
import useHasSelectedWheelStorage from '../../../BookingFlow/hooks/useHasSelectedWheelStorage';
import { BookingScreenV2 } from '../../components/BookingScreenV2';
import { addonsStepPath, tierHotelStepPath, timeWindowStepPath } from '../../components/bookingFlowPathsV2';
import { useIsStepValid } from '../../hooksV2/useStepCompletionV2';
import { useBookingAddress, useBookingCars, useBookingSalesItemIds } from '../../store';

// Dont look at this file mattis, will swap out everything here
export const SalesItemsV2 = () => {
  const navigate = useNavigate();
  const { isSalesItemStepValid } = useIsStepValid();
  // This will come from api now, so no need for this hook anymore
  const hasSelectedWheelStorage = useHasSelectedWheelStorage();
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const { refetch, isLoading: fetchingAddons } = useFetchAddons();
  const [isLoading, setIsLoading] = useState(false);

  const navigateToNextStep = async () => {
    const { data: addons } = await refetch();

    if (hasSelectedWheelStorage) {
      return navigate(tierHotelStepPath);
    }

    if (addons?.length) {
      return navigate(addonsStepPath);
    }

    return navigate(timeWindowStepPath);
  };

  async function onNextButtonClicked() {
    setIsLoading(true);
    await navigateToNextStep();
    setIsLoading(false);
  }
  if (!userGroupId) {
    return <ErrorPageWithTranslations />;
  }

  return (
    <BookingScreenV2
      onNextClicked={onNextButtonClicked}
      disableNextButton={!isSalesItemStepValid}
      title={t`What can we help you with?`}
    >
      <SalesItemsSelector userGroupId={userGroupId} isLoading={fetchingAddons || isLoading} />
    </BookingScreenV2>
  );
};

export const SalesItemsSelector = ({ isLoading, userGroupId }: { isLoading: boolean; userGroupId: number }) => {
  const selectedAddress = useBookingAddress();
  const selectedCars = useBookingCars();
  const selectedSalesItemIds = useBookingSalesItemIds();

  const inputs = selectedCars.map((selectedCar) => ({
    type: URLKeys.getAvailableSalesItemsForCar,
    input: {
      userGroupId,
      addressId: selectedAddress!.id,
      carId: selectedCar.id,
      selectedSalesItemIds
    }
  }));

  const results = noddiAsync.useGetAll(inputs);

  const hasError = results.some((result) => result.error);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (hasError) {
    return <ErrorPageWithTranslations />;
  }

  return (
    <div className='flex flex-col gap-8'>
      {selectedCars.map((selectedCar, index) => (
        <SalesItemsForSelectedCar
          key={selectedCar.licensePlateNumber}
          selectedCar={selectedCar}
          salesItemQuery={results[index]!}
        />
      ))}
    </div>
  );
};

const SalesItemsForSelectedCar = ({
  selectedCar,
  salesItemQuery
}: {
  selectedCar: Car;
  salesItemQuery: UseQueryResult<AvailableSalesItemsForBooking, NoddiAsyncError>;
}) => {
  if (salesItemQuery.isPending) {
    return <LoadingScreen />;
  }

  const salesItemsData = salesItemQuery.data;
  // Assert defined because we check for errors above, want to throw error for whole page if one of the query fails?
  return (
    <SalesItemCar
      //@ts-ignore
      car={selectedCar}
      hasActiveTireHotelContract={salesItemsData!.meta.hasActiveTireHotelContract}
      availableSalesItems={salesItemsData!.salesItems}
    />
  );
};
