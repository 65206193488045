import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiLinearProgressLoader } from 'noddi-ui';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { AddCars } from './AddCars';

export const Car = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  // need to use isLoading here, not isPending if not page doesn't work for not logged in users
  const {
    isLoading: IsSavedCarsLoading,
    data: savedCars,
    error: savedCarsError
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedCarsForUserGroups,
    input: { userGroupId: userGroupId as number, page: 1, pageSize: 1000 },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (IsSavedCarsLoading) {
    return <NoddiLinearProgressLoader />;
  }

  if (savedCarsError) {
    return <ErrorPageWithTranslations apiError={savedCarsError} />;
  }

  return <AddCars savedCars={savedCars?.results} />;
};
