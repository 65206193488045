import { CarDetails } from 'noddi-async/src/types';
import { RegNumberSearchV2 } from 'noddi-ui';
import { CountryCode } from 'noddi-util';
import { useState } from 'react';
import getCommonTranslations from '../../../../commonTranslations';
import { getErrorCodes } from '../../../../utils/translation';
import { getLicensePlateCountryOptions } from '../../../BookingFlow/Steps/Cars/LicensePlateCountryOptions';
import { LicensePlateCountrySelector } from '../../../BookingFlow/Steps/Cars/LicensePlateCountrySelector';
import { useBookingAddCar, useBookingAddress, useBookingCars } from '../../store';
import { Car } from './Car';
import { SavedCars } from './SavedCars';

type AddCarsProps = {
  savedCars?: CarDetails[];
};

export const AddCars = ({ savedCars = [] }: AddCarsProps) => {
  const selectedCars = useBookingCars();
  const selectedAddress = useBookingAddress();
  const addCar = useBookingAddCar();

  const defaultCountryCodeOption = getLicensePlateCountryOptions().find(
    (option) => option.value === selectedAddress?.countryCode
  );

  const [licensePlateCountry, setLicensePlateCountry] = useState(defaultCountryCodeOption);

  const carsFromSearch = selectedCars.filter(
    (selectedCar) => !savedCars.some((savedCar) => savedCar.id === selectedCar.id)
  );

  const alreadyPickedLicensePlateNumbers = selectedCars.map((selectedCar) => selectedCar.licensePlateNumber);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <RegNumberSearchV2
          addCar={addCar}
          errorCodes={getErrorCodes()}
          alreadyPickedLicensePlateNumbers={alreadyPickedLicensePlateNumbers}
          savedCars={savedCars}
          translations={{
            add: getCommonTranslations().add,
            regNumberLabel:
              licensePlateCountry?.value === CountryCode.NORWAY
                ? getCommonTranslations().regNumberLabelNO
                : getCommonTranslations().regNumberLabelSE,
            placeholder:
              licensePlateCountry?.value === CountryCode.NORWAY
                ? getCommonTranslations().regNumberPlaceholderNO
                : getCommonTranslations().regNumberPlaceholderSE
          }}
          countryCode={licensePlateCountry?.value}
        />
        <LicensePlateCountrySelector
          setLicensePlateCountry={setLicensePlateCountry}
          licensePlateCountry={licensePlateCountry}
        />
      </div>
      <div className='flex flex-col gap-4'>
        {carsFromSearch.map((carFromSearch) => (
          <Car key={carFromSearch.licensePlateNumber} car={carFromSearch} />
        ))}
        <SavedCars savedCars={savedCars} />
      </div>
    </div>
  );
};
