import { t } from '@lingui/macro';
import { tracking, useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker } from 'noddi-ui';
import { getTomorrow } from 'noddi-util';

import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import getCommonTranslations from '../../commonTranslations';
import { useBookingContext } from '../../contexts/BookingContext';
import { ErrorPageWithTranslations } from '../ErrorPageWithTrans';

interface TimeWindowPickerProps {
  onNextClicked: (availableBookingTimeWindow: AvailableBookingTimeWindow) => void;
}
const TimeWindowPicker = ({ onNextClicked }: TimeWindowPickerProps) => {
  const { bookingInputData: inputData, salesItems, updateBookingInputData } = useBookingContext();

  const { isSuperUser } = useAuthContext();

  const { serviceAreaId } = inputData;

  if (!serviceAreaId) {
    return (
      <ErrorPageWithTranslations
        errorMessage={t`It looks like there is an configuration error in your area. Please contact us at hei@noddi.no and provide us with your address and selected services, and we will solve this for you as fast as possible`}
      />
    );
  }

  const navigateToNextStep = (timeWindow: AvailableBookingTimeWindow) => {
    setTimeout(() => {
      onNextClicked(timeWindow);
    }, 250);
  };

  // Todo : date should be based on local timezone from address : clickup id : CU-86c1jvzb6
  return (
    <BookingTimeWindowPicker
      serviceAreaId={serviceAreaId}
      salesItemIds={salesItems.map((salesItem) => salesItem.id)}
      fromDate={getTomorrow() ?? new Date()}
      onUnavailableSelect={(timeWindow) => {
        if (!isSuperUser) {
          return;
        }
        updateBookingInputData({
          time: timeWindow
        });

        navigateToNextStep(timeWindow);
      }}
      selectedTimeWindowId={inputData.time?.id}
      onAvailableSelect={(timeWindow) => {
        if (inputData.time && inputData.time?.id !== timeWindow.id) {
          tracking.track('timeWindowChanged');
        }

        updateBookingInputData({
          time: timeWindow
        });

        navigateToNextStep(timeWindow);
      }}
      translations={getCommonTranslations().timePicker}
    />
  );
};

export default TimeWindowPicker;
