/* eslint-disable deprecation/deprecation */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import _ from 'lodash';
import { AddressProps, PlaceType } from 'noddi-async/src/types';
import { CountryCode } from 'noddi-util';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import { NoddiIcon } from '../../../../atoms';
import { NoddiFeedbackBox, NoddiIconButton } from '../../../../molecules';
import { hasStreetNumber } from '../addressUtils';
import { geocodeByAddress } from './geocode';
import { ComponentRestrictions } from './interface';

const axiosInstance = axios.create();

const autocompleteService = { current: null };
const geocoder = { current: null };

const _ALLOWED_COUNTRIES = [CountryCode.NORWAY, CountryCode.SWEDEN];

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export type GoogleMapsSearchProps = {
  address?: AddressProps | null;
  setAddress: (value: AddressProps | null) => void;
  isLoading?: boolean;
  setIsLoading?: (value: boolean) => void;
  placeIdFromUrl?: string | null;
  translations: {
    tryAgainWithStreetNumber: string;
    addressNotFound: string;
    couldNotFindAddress: string;
  };
};

export const GoogleMapsPlaceSearch = ({
  address,
  setAddress,
  isLoading,
  setIsLoading,
  placeIdFromUrl,
  translations
}: GoogleMapsSearchProps) => {
  const [value, setValue] = useState<PlaceType | null>(address?.placeType ? address.placeType : null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype&loading=async`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            componentRestrictions: ComponentRestrictions;
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  type AddressComponentType =
    | 'street_number'
    | 'route'
    | 'locality'
    | 'postal_town'
    | 'administrative_area_level_1'
    | 'postal_code'
    | 'country';

  const findByType = (
    addrObj: google.maps.GeocoderAddressComponent[],
    type: AddressComponentType
  ): google.maps.GeocoderAddressComponent | undefined => {
    return _.find(addrObj, (o) => {
      return o.types[0] === type;
    });
  };

  const getAddressFromPlaceType = async (placeType: PlaceType | null): Promise<AddressProps | null> => {
    if (placeType?.description) {
      setIsLoading?.(true);
      const geo = (await geocodeByAddress(placeType.description)) as google.maps.GeocoderResult[];

      if (!geo || geo.length === 0) {
        throw new Error('Invalid or missing geo');
      }

      const result = geo.find(({ address_components }) => !!findByType(address_components, 'street_number')) ?? geo[0];

      if (!result || !result.geometry || !result.geometry.location) {
        throw new Error('Invalid or missing geometry data');
      }

      const { lat, lng } = getLatLng(result);
      const addrObjs = result.address_components;

      const streetNumberType = findByType(addrObjs, 'street_number');
      const route = findByType(addrObjs, 'route');
      let city = findByType(addrObjs, 'locality');
      if (!city) {
        city = findByType(addrObjs, 'postal_town');
      }

      const postalCode = findByType(addrObjs, 'postal_code');
      let postalSafeGuardCode = null;
      // sometimes we do not get the postal code from Google, so we try to fetch it elsewhere
      if (!postalCode) {
        await axiosInstance
          .get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)
          .then((res) => {
            // eslint-disable-next-line promise/always-return
            postalSafeGuardCode = res.data.address?.postcode ?? '';
          });
      }

      const streetNumber = streetNumberType?.long_name;

      const country = findByType(addrObjs, 'country');
      setIsLoading?.(false);

      const streetName = route?.long_name ?? '';
      const zipCode = postalCode?.long_name ?? postalSafeGuardCode;
      const cityName = city?.long_name ?? '';
      const countryName = country?.long_name ?? '';
      const countryCode = country?.short_name ?? '';
      const fullAddress = `${streetName} ${streetNumber}, ${zipCode} ${cityName}, ${countryName}`;

      return {
        id: undefined,
        name: streetName,
        streetName,
        streetNumber,
        zipCode,
        city: cityName,
        country: countryName,
        countryCode,
        latitude: lat,
        longitude: lng,
        fullAddress,
        placeType,
        description: placeType.description
      } as AddressProps;
    } else {
      return null;
    }
  };

  useEffect(() => {
    //if we get address from url we populate the store accordingly
    // the setTimeout is to ensure that Google Maps script is loaded
    if (placeIdFromUrl && loaded.current) {
      setTimeout(async () => {
        const parameter = { placeId: placeIdFromUrl };
        const results = await getGeocode(parameter);
        const result = results[0];

        const placeType = {
          place_id: placeIdFromUrl,
          description: result?.formatted_address,
          structured_formatting: {
            main_text: result?.formatted_address,
            secondary_text: result?.formatted_address
          }
        } as PlaceType;
        setValue(placeType);
        const address = await getAddressFromPlaceType(placeType);
        setAddress(address);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeIdFromUrl]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!geocoder.current && (window as any).google) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (!geocoder.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // only show street addresses
    // TODO: right now user don't get hit on search unless if its an actual address which is a bit cumbersome

    fetch(
      { input: inputValue, componentRestrictions: { country: _ALLOWED_COUNTRIES } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Stack>
      <Autocomplete
        key={`${address?.latitude}${address?.longitude}`}
        disabled={isLoading}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={translations.addressNotFound}
        onChange={async (_, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          try {
            const address = await getAddressFromPlaceType(newValue);

            if (!hasStreetNumber(address)) {
              setErrorMessage(translations.tryAgainWithStreetNumber);
              return;
            }
            setAddress(address);
            setErrorMessage(undefined);

            if (inputRef?.current) {
              inputRef.current?.blur();
            }
          } catch (e) {
            setErrorMessage(translations.couldNotFindAddress);
          }
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            inputRef={inputRef}
            label='Address'
            InputLabelProps={{
              sx: {
                ml: '40px',
                transition: 'all 0.2s ease-in-out'
              },
              shrink:
                (inputRef?.current?.value && inputRef?.current?.value?.length > 0) ||
                document.activeElement === inputRef.current // Shrink label when there's input or focus
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start' className='relative bottom-4'>
                  <NoddiIcon name='LocationPin' size='large' />
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props} className={`${props.className} cursor-pointer`}>
              <div className='flex w-full items-center'>
                <div className='w-[calc(100%-44px)] break-words'>
                  {parts.map((part, index) => (
                    <span key={index} className={part.highlight ? 'font-bold' : 'font-normal'}>
                      {part.text}
                    </span>
                  ))}
                  <p className='text-3 text-secondary-black'>{option.structured_formatting.secondary_text}</p>
                </div>
                <div className='flex w-11'>
                  <NoddiIconButton iconName='ArrowRight' variant='ghost' iconSize='medium' />
                </div>
              </div>
            </li>
          );
        }}
      />

      {errorMessage && <NoddiFeedbackBox className='mt-4' variant='error' heading={errorMessage} />}
    </Stack>
  );
};
