import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  ErrorPage,
  getLongAddressName,
  LoadingScreen,
  NoddiBasicCard,
  NoddiButton,
  NoddiFeedbackBox,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader
} from 'noddi-ui';
import { AddressSearch } from 'noddi-ui/src/components/Domains/Address/GoogleAddress/AddressSearch';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../appRoutes';
import getCommonTranslations from '../../../../commonTranslations';
import { BookingScreenV2 } from '../../components/BookingScreenV2';
import { useIsStepValid } from '../../hooksV2/useStepCompletionV2';
import { useBookingAddress, useBookingSetAddress } from '../../store';
import { NoServiceOfferedV2 } from './NoServiceOfferedV2';

const Address = () => {
  const setSelectedAddress = useBookingSetAddress();
  const selectedAddress = useBookingAddress();

  const { isAddressStepValid } = useIsStepValid();

  const { currentUserGroupId: userGroupId } = useAuthContext();

  const navigate = useNavigate();

  const navigateToCarsStep = () => {
    navigate(routes.newCarsV2.getPath());
  };

  const {
    data: savedAddresses,
    isLoading,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddressesValidForNewBooking,
    input: { id: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const {
    mutateAsync: createAddressFromPlaceId,
    reset: resetCreateAddressFromPlaceId,
    isPending,
    error: addressError,
    data: createdAddress
  } = noddiAsync.usePost({
    type: URLKeys.postAddressCreatedFromPlaceId,
    queryConfig: {
      onSuccess: ({ data: { isInDeliveryArea, ...rest } }) => {
        if (isInDeliveryArea) {
          setSelectedAddress(rest);
          navigateToCarsStep();
        }
      }
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const displayAddress = selectedAddress || createdAddress?.data;

  const unPickedSavedAddresses = savedAddresses?.filter((savedAddress) => savedAddress.id !== displayAddress?.id);

  return (
    <BookingScreenV2
      title={t`Where is your car parked?`}
      onNextClicked={navigateToCarsStep}
      disableNextButton={!isAddressStepValid}
      hideShoppingCart
    >
      {displayAddress ? (
        <NoddiBasicCard className='flex w-full items-center justify-between gap-3 rounded-md pl-2 '>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='LocationPin' size='large' />
            <span>{getLongAddressName(displayAddress)}</span>
          </div>
          <NoddiIconButton
            iconName='Cross'
            iconSize='medium'
            variant='destructive'
            onClick={() => {
              resetCreateAddressFromPlaceId();
              setSelectedAddress(undefined);
            }}
          />
        </NoddiBasicCard>
      ) : (
        <>
          <AddressSearch
            translations={{ noAddressesFound: t`No addresses found` }}
            onChange={async (addressSuggestion) => {
              if (addressSuggestion) {
                await createAddressFromPlaceId({ placeId: addressSuggestion.placeId });
              }
            }}
          />
          {isPending && <NoddiLinearProgressLoader />}
          {addressError && (
            /*   // TODO | New booking flow |  ideally would map api errors with errorcodes?*/
            <NoddiFeedbackBox variant='error' className='mt-4' heading={getCommonTranslations().couldNotFindAddress} />
          )}
        </>
      )}
      {createdAddress && !createdAddress.data.isInDeliveryArea && (
        <NoServiceOfferedV2
          translations={{
            noServiceOfferedNotice: getCommonTranslations().noServiceOfferedNotice
          }}
          address={createdAddress.data}
        />
      )}
      {!!unPickedSavedAddresses?.length && (
        <div className='mt-13 flex flex-col gap-2'>
          <span className='font-bold text-primary-darkPurple'>
            <Trans>Saved addresses</Trans>
          </span>

          <div className='flex flex-col gap-4'>
            {unPickedSavedAddresses.map((savedAddress) => (
              <NoddiBasicCard key={savedAddress.id} className='flex items-center justify-between gap-4'>
                <span>{getLongAddressName(savedAddress)}</span>
                <NoddiButton
                  endIcon='ArrowRight'
                  variant='secondary'
                  size='small'
                  onClick={() => {
                    setSelectedAddress(savedAddress);
                    navigateToCarsStep();
                  }}
                >
                  <Trans>Choose</Trans>
                </NoddiButton>
              </NoddiBasicCard>
            ))}
          </div>
        </div>
      )}
    </BookingScreenV2>
  );
};

export default Address;
