import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { URLKeys, noddiAsync } from 'noddi-async';
import { CarDetails } from 'noddi-async/src/types';
import { CountryCode, CountryCodeType } from 'noddi-util';
import { useEffect, useRef, useState } from 'react';

import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import NorwegianLicensePlateImg from '../../../images/norwegian_license_plate.png';
import SwedishLicensePlateImg from '../../../images/swedish_license_plate.png';
import { NoddiButton } from '../../Elements';
import { ErrorMessage, mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';
import { ErrorCodes } from '../../Elements/Errors/types';
import { isLicensePlateNumberAlreadyPicked } from './utils/isLicensePlateNumberAlreadyPicked';
import { isValidLicensePlateNumber } from './utils/isValidLicensePlateNumber';

type Props = {
  addCar: (carProps: CarSpec) => void;
  alreadyPickedLicensePlateNumbers?: string[];
  savedCars?: CarDetails[];
  translations: {
    add: string;
    regNumberLabel: string;
    placeholder: string;
  };
  countryCode?: CountryCodeType;
  errorCodes: ErrorCodes;
};
export const RegNumberSearchV2 = ({
  addCar,
  alreadyPickedLicensePlateNumbers = [],
  savedCars = [],
  translations,
  countryCode = CountryCode.NORWAY,
  errorCodes
}: Props) => {
  const [regNumber, setRegNumber] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, data, refetch, isLoading } = noddiAsync.useGet({
    type: URLKeys.getCarFromLicensePlateNumber,
    input: { licensePlateNumber: regNumber, countryCode },
    queryConfig: {
      enabled: false,
      staleTime: Infinity
    }
  });

  const licensePlateNumberAlreadyPicked = isLicensePlateNumberAlreadyPicked(
    alreadyPickedLicensePlateNumbers,
    regNumber,
    savedCars
  );

  useEffect(() => {
    if (data) {
      addCar(data);
      setRegNumber('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const acceptedRegLength = countryCode === 'NO' ? 7 : 6;

  const searchInputDisabled = regNumber.length !== acceptedRegLength || licensePlateNumberAlreadyPicked;

  const handleRegNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toUpperCase();

    if (isValidLicensePlateNumber({ input, countryCode })) {
      setRegNumber(input);
    }
  };

  const licensePlateImgSource = countryCode === 'NO' ? NorwegianLicensePlateImg : SwedishLicensePlateImg;
  const altTag = countryCode === 'NO' ? 'Norwegian license plate' : 'Swedish license plate';
  const shouldShrink = isFocused || regNumber.length > 0;

  return (
    <div className='flex flex-col'>
      <div className='flex w-full items-center gap-3'>
        <TextField
          inputRef={inputRef}
          label={translations.regNumberLabel}
          placeholder={translations.placeholder}
          fullWidth
          value={regNumber}
          onChange={handleRegNumberChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              !searchInputDisabled && refetch();
            }
          }}
          inputProps={{
            maxLength: acceptedRegLength
          }}
          InputLabelProps={{
            sx: {
              transition: 'all 0.2s ease-in-out',
              pl: '8px',
              ml: shouldShrink ? '16px' : '30px'
            },
            shrink: shouldShrink // Shrink label when focused or when there's input
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' className='!mt-0'>
                <img src={licensePlateImgSource} alt={altTag} className='h-14 w-7' />
              </InputAdornment>
            ),
            sx: {
              left: 0,
              top: 0,
              bottom: 0,
              height: '100%',
              padding: 0,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
        />

        <NoddiButton
          className='flex h-13'
          variant='secondary'
          startIcon='Plus'
          onClick={() => {
            refetch();
          }}
          loading={isLoading}
          disabled={searchInputDisabled}
        >
          {translations.add}
        </NoddiButton>
      </div>

      {error && (
        <div className='mb-5'>
          <ErrorMessage message={`${mapErrorCodeToUiMessage({ error, errorCodes })}: ${regNumber}`} />
        </div>
      )}
    </div>
  );
};
