import { MembershipProgramProps, SalesItemSlugs } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { roundNumber } from 'noddi-util';

import { SelectedSalesItem, SelectedSalesItemFull } from '../pages/BookingFlow/interfaces';
import { ActiveCoupon } from '../stores/CouponStore';
import { getCouponDiscountAmount, getDiscountedPrice } from './discount';

export const getSalesItemIconBySlug = (slug: string, dimension: number = 150) => {
  switch (slug) {
    case SalesItemSlugs['nb-tire-storage-home-delivery']:
      return <img src='/assets/svgs/Warehouse.svg' width={dimension} height={dimension} />;
    case SalesItemSlugs['nb-tire-storage-cancel-and-wheel-change']:
      return <img src='/assets/svgs/CarryingTires.svg' width={dimension} height={dimension} />;
    case SalesItemSlugs['nb-tire-storage-pickup-yourself']:
      return <img src='/assets/svgs/MapWithIcon.svg' width={dimension} height={dimension} />;
    default:
      return <NoddiIcon name='QuestionMark' size='extraLarge' color={colors.primary.black} />;
  }
};

type BookingDiscountProps = {
  coupons: ActiveCoupon[];
  membershipPrograms: MembershipProgramProps[] | undefined;
  salesItem: SelectedSalesItemFull;
};

export const getBookingDiscountProps = ({ membershipPrograms, coupons, salesItem }: BookingDiscountProps) => {
  const membershipDiscounts =
    membershipPrograms?.flatMap((membershipProgram) =>
      membershipProgram.discounts.map((discount) => ({
        ...discount,
        membershipProgramId: membershipProgram.id
      }))
    ) ?? [];

  const discount = membershipDiscounts.find((discount) => discount.salesItemIds.includes(salesItem.id));
  const couponDiscounts = coupons.filter((coupon) => coupon.usedOnSalesItemId === salesItem.id);

  const membershipDiscount = discount
    ? {
        membershipProgramId: discount.membershipProgramId,
        membershipDiscountId: discount.id,
        discount: roundNumber({ number: (salesItem.price * Number(discount.percentage)) / 100, decimalPlaces: 2 })
      }
    : null;

  return {
    couponDiscounts: couponDiscounts.map((couponDiscount) => {
      return {
        couponId: couponDiscount.id,
        discount: getCouponDiscountAmount(couponDiscount, salesItem.price)
      };
    }),
    membershipDiscount: membershipDiscount?.discount !== 0 ? membershipDiscount : null
  };
};

// /Todo : this method is highly prone to bugs, as whenever BE introduces new TH items, we need to update this method manually.
// https://app.clickup.com/t/86c1mfe95 - 14/01/2024
export const isWheelStorageNewOrder = (salesItem: SelectedSalesItem) => {
  return (
    salesItem.slug === SalesItemSlugs['nb-tire-storage-included-wheel-change-lg'] ||
    salesItem.slug === SalesItemSlugs['nb-tire-storage-included-wheel-change-sm'] ||
    salesItem.slug === SalesItemSlugs['nb-tire-storage-only-storage'] ||
    salesItem.slug === SalesItemSlugs['nb-tire-storage-renew-tire-storage-with-wheel-change']
  );
};

export const findWheelStorageSalesItems = (selectedSalesItems: SelectedSalesItem[]) => {
  const salesItemsWithNewWheelStorageSalesItem = selectedSalesItems.filter((item) => isWheelStorageNewOrder(item));

  // ensure that we only have one wheel storage per car
  return salesItemsWithNewWheelStorageSalesItem.filter(
    (item, index, self) => self.findIndex((i) => i.licensePlateNumber === item.licensePlateNumber) === index
  );
};

type TotalPriceParams = {
  salesItems: SelectedSalesItem[];
  coupons: ActiveCoupon[];
  windowSlotPrice: number;
  membershipData: MembershipProgramProps[] | undefined;
};
export const getTotalPrice = ({ salesItems, coupons, windowSlotPrice, membershipData }: TotalPriceParams) => {
  const discountFromMemberships = membershipData?.flatMap((membershipProgram) => membershipProgram.discounts) ?? [];

  return roundNumber({
    number:
      windowSlotPrice +
      salesItems.reduce((accumulator, salesItem) => {
        const relevantCoupons = coupons.filter((x) => x.usedOnSalesItemId === salesItem.id);

        return (
          accumulator + getDiscountedPrice({ discounts: discountFromMemberships, salesItem, coupons: relevantCoupons })
        );
      }, 0),
    decimalPlaces: 2
  });
};
