import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { BookingScreenV2 } from '../../components/BookingScreenV2';
import { salesItemStepPath } from '../../components/bookingFlowPathsV2';
import { useIsStepValid } from '../../hooksV2/useStepCompletionV2';
import { Car } from './Cars';

export const Cars = () => {
  const { isCarStepValid } = useIsStepValid();
  const navigate = useNavigate();
  return (
    <BookingScreenV2
      onNextClicked={() => navigate(salesItemStepPath)}
      disableNextButton={!isCarStepValid}
      title={t`Add one or more cars`}
      hideShoppingCart
      showSelectedCars
    >
      <Car />
    </BookingScreenV2>
  );
};
