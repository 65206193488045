/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { noddiAsync } from './index';
import { InputData, URLKeysTypes } from './resources/resources';
import { PaginatedResponse } from './types';

import { ReturnType as NoddiAsyncReturnType } from './NoddiAsync';

type ConvertToCamelCase = ReturnType<typeof snakecaseKeys>;

export function convertObjectKeysToCamelCase(variableToChange: ConvertToCamelCase): ConvertToCamelCase {
  return camelcaseKeys(variableToChange, { deep: true });
}

type ConvertToSnakeCase = Record<string | number, unknown> | Record<string | number, unknown>[];

export function convertObjectKeysToSnakeCase(variableToChange: ConvertToSnakeCase) {
  return snakecaseKeys(variableToChange, {
    deep: true
  });
}

export function downloadToExcel(
  res: AxiosResponse<
    ArrayBufferLike,
    {
      status: number;
      statusText: string;
      headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
      config: any;
      request?: any;
    }
  >
) {
  const contentDisposition = res.headers['content-disposition'];
  const filename = contentDisposition
    ? //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (contentDisposition.split('filename=')[1].split(';')[0] as string)
    : 'file.csv';
  const bytes = new Uint8Array(res.data);
  const blob = new Blob([bytes]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  return null;
}

export function getAllCachedDataFromUrlKey<T>({
  urlKey,
  input
}: {
  urlKey: URLKeysTypes;
  input?: InputData<URLKeysTypes>;
}): T[] {
  const queryKey = input ? [urlKey, input] : [urlKey];
  const queriesData = noddiAsync.queryClient.getQueriesData({ queryKey });

  return (queriesData?.filter((queryResult) => queryResult?.[1]).flatMap((queryResult) => queryResult?.[1]) ??
    []) as T[];
}

export function invalidateQueryExactMatch<UrlKey extends URLKeysTypes>({
  urlKey,
  input
}: {
  urlKey: UrlKey;
  input?: InputData<UrlKey>;
}) {
  const queryKey = input ? [urlKey, input] : [urlKey];

  return noddiAsync.queryClient.invalidateQueries({ queryKey });
}

export function invalidateQueryKey<UrlKey extends URLKeysTypes>({ urlKey }: { urlKey: UrlKey }) {
  return noddiAsync.queryClient.invalidateQueries({ queryKey: [urlKey] });
}

export function getAllCachedDataFromPaginatedResponseUrlKey<T>({ urlKey }: { urlKey: URLKeysTypes }): T[] {
  const queriesData = getAllCachedDataFromUrlKey<PaginatedResponse<T>>({ urlKey });

  return queriesData.flatMap((queryResult) => queryResult.results);
}

export function getCachedDataFromExactMatch<UrlKey extends URLKeysTypes>({
  urlKey,
  input
}: {
  urlKey: UrlKey;
  input?: InputData<UrlKey>;
}): NoddiAsyncReturnType<UrlKey, InputData<UrlKey>> | undefined {
  const queryKey = input ? [urlKey, input] : [urlKey];

  return noddiAsync.queryClient.getQueryData(queryKey);
}
