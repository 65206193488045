export const getFlagFromCountryCode = (languageCode?: string) => {
  switch (languageCode) {
    case 'en':
      return '🇬🇧';
    case 'no':
      return '🇳🇴';
    case 'se':
      return '🇸🇪 ';
    default:
      return '🌍';
  }
};

export const getLanguagePropertiesFromCountryCode = (languageCode?: string) => {
  switch (languageCode) {
    case 'en-us':
      return {
        name: 'English',
        flag: '🇬🇧'
      };
    case 'nb':
      return {
        name: 'Norwegian',
        flag: '🇳🇴'
      };
    case 'sv':
      return {
        name: 'Swedish',
        flag: '🇸🇪'
      };
    case 'de':
      return {
        name: 'German',
        flag: '🇩🇪'
      };
    default:
      return {
        name: 'Unknown',
        flag: ''
      };
  }
};
