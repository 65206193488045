import { NoddiSelectableItem } from 'noddi-ui/src/molecules/NoddiSelectableItem';

import { t } from '@lingui/macro';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { useBookingAddCar, useBookingCars, useBookingRemoveCar } from '../../store';

type SavedCarProps = {
  savedCars: CarSpec[];
};

export function SavedCars({ savedCars }: SavedCarProps) {
  const selectedCars = useBookingCars();
  const addCar = useBookingAddCar();
  const removeCar = useBookingRemoveCar();

  return (
    <>
      {savedCars.map((car) => {
        const isSelected = selectedCars.some((selectedCar) => selectedCar.id === car.id);
        return (
          <NoddiSelectableItem
            title={`${car.make} - ${car.model}`}
            description={car.licensePlateNumber}
            iconName='Car'
            isSelected={isSelected}
            action={isSelected ? () => removeCar(car) : () => addCar(car)}
            actionText={isSelected ? t`Remove` : t`Add`}
            key={car.licensePlateNumber}
          />
        );
      })}
    </>
  );
}
