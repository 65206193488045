import * as Sentry from '@sentry/react';
import { noddiAsync, URLKeys } from 'noddi-async';
import { NoddiAsyncError, UserDataProps, UserGroupsType } from 'noddi-async/src/types';
import { forbiddenPageUrl } from 'noddi-ui';
import { storage } from 'noddi-util';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { UserRoleType } from '../types';
import { hasValidPermission } from '../utils';
import { AuthContext } from './AuthContext';

const TOKEN = 'noddi-token';
const IMPERSONATED_TOKEN = 'noddi-impersonated-token';
const currentUserGroupIdKey = 'noddi-current-user-group-id';
const impersonatedUserGroupIdKey = 'noddi-impersonated-user-group-id';

interface AuthProviderProps {
  children: ReactNode;
  requiredLoginLevels?: UserRoleType[];
  userGroupsType?: UserGroupsType;
}

/**
 * The AuthProvider is used to provide authentication to the app.
 * If no requiredLoginLevel is provided, the user will be logged in if they have a token. This is
 * true for the customer app and the worker app.
 */
export const AuthProvider = ({ children, requiredLoginLevels, userGroupsType = 'all' }: AuthProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserDataProps | null>(null);
  const [impersonatedUser, setImpersonatedUser] = useState<UserDataProps | null>(null);

  const { mutateAsync, isPending, isError, error } = noddiAsync.usePost({
    type: URLKeys.postTokenLogin
  });

  const getToken = () => localStorage.getItem(TOKEN);

  const getImpersonatedToken = () => localStorage.getItem(IMPERSONATED_TOKEN);

  // on refresh - we need to run the mutation for token login as a get request -
  // to get correct loading state from react query on mount
  const { isPending: isTokenLoadingOnMount, isFetching } = noddiAsync
    .getReactQuery()
    .useQuery<unknown, NoddiAsyncError>({
      queryKey: ['tokenLogin'],
      queryFn: () => tokenLoginOfUsers(),
      enabled: !userData
    });

  const updateUserData = (data: UserDataProps) => {
    sessionStorage.clear();
    setUserData(data);
    setCurrentUserGroupId(data);
  };

  const updateImpersonatedData = (data: UserDataProps) => {
    localStorage.setItem(IMPERSONATED_TOKEN, data.token);
    noddiAsync.setImpersonatedAuthToken(data.token);

    setImpersonatedUser(data);
    setImpersonatedUserGroupId(data);
  };

  const clearImpersonatedUser = () => {
    localStorage.removeItem(IMPERSONATED_TOKEN);
    noddiAsync.setImpersonatedAuthToken('');
    setImpersonatedUser(null);
    storage.session.removeItem(impersonatedUserGroupIdKey);
  };

  const tokenLogin = async (token: string) => {
    return mutateAsync({ token, userGroupsType });
  };

  const setCurrentUserGroupId = (userData: UserDataProps) => {
    const userGroupId = userData.user.userGroups.find((userGroup) => userGroup.isSelected)?.id;
    if (!userGroupId) {
      return;
    }
    storage.session.setItem(currentUserGroupIdKey, userGroupId);
  };

  const setDefaultUserGroupId = (userData: UserDataProps) => {
    const userGroupId = userData.user.userGroups.find(
      (userGroup) => userGroup.isDefaultUserGroup ?? userGroup.isSelected
    )?.id;
    if (!userGroupId) {
      return;
    }
    storage.session.setItem(currentUserGroupIdKey, userGroupId);
  };

  const setImpersonatedUserGroupId = (userData: UserDataProps) => {
    const userGroupId = userData.user.userGroups.find((userGroup) => userGroup.isSelected)?.id;
    if (!userGroupId) {
      return;
    }
    storage.session.setItem(impersonatedUserGroupIdKey, userGroupId);
  };

  const loginUser = (userData: UserDataProps) => {
    if (requiredLoginLevels) {
      if (!hasValidPermission(requiredLoginLevels, userData.user)) {
        return navigate(forbiddenPageUrl);
      }
    }

    noddiAsync.setAuthToken(userData.token);
    localStorage.setItem(TOKEN, userData.token);
    setUserData(userData);
    setDefaultUserGroupId(userData);
  };

  const getServiceDepartmentId = () => {
    const userData = getUserData();
    if (!userData) {
      return;
    }
    return userData.user.serviceWorker?.serviceDepartmentId;
  };

  const tokenLoginOfUsers = async () => {
    const token = localStorage.getItem(TOKEN);
    const impersonatedToken = localStorage.getItem(IMPERSONATED_TOKEN);

    if (impersonatedToken) {
      await tokenLogin(impersonatedToken)
        .then((r) => {
          const data = r.data;
          // eslint-disable-next-line promise/always-return
          if (!data) {
            return;
          }
          noddiAsync.setImpersonatedAuthToken(data.token);
          setImpersonatedUser(data);
          setImpersonatedUserGroupId(data);
        })
        .catch(() => {
          logout();
        });
    }
    if (token) {
      await tokenLogin(token)
        .then((r) => {
          const data = r.data;
          if (!data) {
            return;
          }

          if (requiredLoginLevels) {
            if (!hasValidPermission(requiredLoginLevels, data.user)) {
              logout();
              return;
            }
          }

          noddiAsync.setAuthToken(data.token);
          localStorage.setItem(TOKEN, data.token);

          setUserData(data);

          // If we already have a user group id in session storage, don't override and set a new one
          const currentUserGroupId = getCurrentUserGroupId();
          // eslint-disable-next-line promise/always-return
          if (!currentUserGroupId) {
            setCurrentUserGroupId(data);
          }
        })
        .catch(() => {
          logout();
        });
    }

    return null;
  };

  useEffect(() => {
    if (userData) {
      Sentry.setUser({
        id: userData.user.id,
        username: `${userData.user.firstName} ${userData.user.lastName}`,
        email: userData.user.email,
        phone: userData.user.phoneNumber,
        language: userData.user.language
      });
    } else {
      Sentry.setUser(null);
    }
  }, [userData]);

  const getCurrentUserGroupId = (): number | undefined => {
    if (impersonatedUser) {
      return storage.session.getItem(impersonatedUserGroupIdKey) ?? undefined;
    }

    return storage.session.getItem(currentUserGroupIdKey) ?? undefined;
  };

  const getNumberOfMembersInCurrentUserGroup = (): number | undefined => {
    if (!userData) {
      return;
    }

    return impersonatedUser
      ? impersonatedUser?.user.userGroups.find((userGroup) => userGroup.isSelected)?.numMembers
      : getUserData()?.user.userGroups.find((userGroup) => userGroup.isSelected)?.numMembers;
  };

  const getCurrentUserGroupMemberId = () => {
    if (impersonatedUser) {
      return impersonatedUser?.user.userGroups.find((userGroup) => userGroup.isSelected)?.userGroupMemberId;
    }
    return getUserData()?.user.userGroups.find((userGroup) => userGroup.isSelected)?.userGroupMemberId;
  };

  const logout = async () => {
    // Clear token from LocalStorage
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(IMPERSONATED_TOKEN);
    noddiAsync.setAuthToken('');
    storage.session.clear();

    setUserData(null);
    setImpersonatedUser(null);
    await noddiAsync.queryClient.resetQueries();

    // Redirect  the user to log in with action
    navigate('/login', { replace: true, state: { from: location } });
    return <Navigate to='/login' replace state={{ from: location }} />;
  };

  const isSuperUser = Boolean(userData?.user?.isSuperuser);

  // ensures that user group entries displays the correct selected user group at any times
  // this is needed because current user group is stored in session storage, and userData lives
  // only in application memory and is not persisted. Hence, we need to make sure that the user
  // group entries are always up to date with the current user group
  const getUserData = () => {
    const data = impersonatedUser || userData;
    if (!data) {
      return null;
    }

    const currentUserGroupId = getCurrentUserGroupId();
    if (!currentUserGroupId) {
      return data;
    }

    const { userGroups } = data.user;
    if (!userGroups) {
      return data;
    }

    const selectedUserGroup = userGroups.find((userGroup) => userGroup.id === currentUserGroupId);
    if (!selectedUserGroup) {
      return data;
    }

    const newUserGroups = userGroups.map((userGroup) => {
      return {
        ...userGroup,
        isSelected: userGroup.id === currentUserGroupId
      };
    });

    return {
      ...data,
      user: {
        ...data.user,
        userGroups: newUserGroups
      }
    };
  };

  const contextData = {
    userData: getUserData(),
    updateUserData,
    setImpersonatedUser,
    impersonatedUser,
    getToken,
    getCurrentUserGroupId,
    numberOfMembersInCurrentUserGroup: getNumberOfMembersInCurrentUserGroup(),
    getCurrentUserGroupMemberId,
    currentUserGroupId: getCurrentUserGroupId(),
    logout,
    tokenLogin,
    isSuperUser,
    isLoggedIn: !!noddiAsync.getAuthToken(),
    getImpersonatedToken,
    updateImpersonatedData,
    canActivateCouponsForNewUsersOnly: userData?.user.canActivateCouponsForNewUsersOnly || false,
    superUser: userData,
    clearImpersonatedUser,
    isTokenLoginLoading: isPending,
    isTokenLoginError: isError,
    tokenLoginError: error,
    loginUser,
    couldBeLoggedIn: Boolean(getToken() || getImpersonatedToken()),
    serviceDepartmentId: getServiceDepartmentId(),
    hasCoupons: getUserData()?.user.hasCoupons,
    hasMemberships: getUserData()?.user.userGroups?.some((group) => group.hasMemberships),
    hasTireHotel: getUserData()?.user.userGroups?.some((group) => group.hasTireHotels),
    tokenLoginOfUsers,
    isTokenLoadingOnMount: isTokenLoadingOnMount || isFetching
  };

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}
