import { useBookingAddress, useBookingCars } from '../../BookingFlowV2/store';

// This is ofc very silly now, but following this pattern from earlier, and if these checks become more complex I think this can be clean to have in one place here.
export const useIsStepValid = () => {
  const selectedAddress = useBookingAddress();
  const selectedCars = useBookingCars();

  const isAddressStepValid = !!selectedAddress?.id;

  const isCarStepValid = !!selectedCars?.length;

  // TODO | New booking flow | fix these
  const isSalesItemStepValid = true;

  // This one needs to take into account that som orders dont have hotel
  const isTierHotelStepValid = true;

  const isTimeWindowsStepValid = true;

  return { isAddressStepValid, isCarStepValid, isSalesItemStepValid, isTierHotelStepValid, isTimeWindowsStepValid };
};
