import { t } from '@lingui/macro';
import { ServerTypes, URLKeys } from 'noddi-async';
import { NoddiCollapseCard } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { SelectedCar } from '../../interfaces';
import { BOOKING_CATEGORY_ENUM } from '../SalesItems/utils';
import { SalesItem } from './SalesItem';
import { ServiceCategoryItem } from './ServiceCategoryItem';

type ServiceItemProps = {
  serviceName: string;
  priceFrom?: number;
  description?: string;
  type: BOOKING_CATEGORY_ENUM;
  isPopular?: boolean;
  salesItems?: ServerTypes.AvailableSalesItem[];
  selectedItemsCount?: number;
  car: SelectedCar;
  isAddon: boolean;
};

const ServiceCategoryBlock = ({
  serviceName,
  description,
  priceFrom,
  type,
  isPopular,
  salesItems,
  car,
  selectedItemsCount,
  isAddon
}: ServiceItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { bookingInputData } = useBookingContext();
  const { selectSalesItem } = useSelectSalesItem();

  const handleSalesItemClick = async (item: ServerTypes.AvailableSalesItem) => {
    const queryParams = {
      queryInput: {
        carSizeId: car.carSizeId,
        latitude: bookingInputData.address!.latitude,
        longitude: bookingInputData.address!.longitude,
        serviceAreaIds: bookingInputData.serviceAreas.flatMap((value) => value.id),
        licensePlateNumber: car.licensePlateNumber
      },
      urlKey: URLKeys.getAvailableSalesItemsForBooking
    };

    await selectSalesItem({
      licensePlateNumber: car.licensePlateNumber,
      salesItem: item,
      queryParams,
      isAddon
    });
  };

  return (
    <NoddiCollapseCard
      sx={{ padding: 0, borderRadius: '16px' }}
      preventHandleExpand
      expandedChanged={(expanded) => setIsExpanded(expanded)}
      mountAsExpanded={isAddon}
      hideChevron
      header={
        <ServiceCategoryItem
          selectedItemsCount={selectedItemsCount ?? 0}
          serviceName={serviceName}
          description={description}
          priceFrom={priceFrom ? `${t`from`} ${formatCurrencyAmount(priceFrom, 0)}` : undefined}
          isPopular={isPopular}
          popularLabel={t`Popular`}
          type={type}
          isExpanded={isExpanded}
        />
      }
      collapseBody={
        <div className='flex flex-col gap-1 px-3 pb-3'>
          {salesItems?.map((item) => (
            <SalesItem
              key={item.name}
              name={item.name}
              description={item.description}
              shortDescription={item.shortDescription}
              price={item.price}
              salesItem={item}
              car={car}
              onSalesItemClick={() => {
                handleSalesItemClick(item);
              }}
            />
          ))}
        </div>
      }
    />
  );
};

export { ServiceCategoryBlock };
