import { MembershipProgramProps } from 'noddi-async/src/types';

import { useBookingContext } from '../contexts/BookingContext';
import { getTotalPrice } from '../helper/salesItems';
import { useCouponStore } from '../stores/CouponStore';

const getDiscounts = (memberShipPrograms: MembershipProgramProps[] | undefined) => {
  if (!memberShipPrograms) {
    return [];
  }
  return memberShipPrograms.flatMap((membershipProgram) => membershipProgram.discounts);
};

type UseBookingSummaryProps = {
  membershipData?: MembershipProgramProps[] | undefined;
};
const useBookingSummaryProps = ({ membershipData }: UseBookingSummaryProps) => {
  const { bookingInputData, salesItems } = useBookingContext();
  const { coupons } = useCouponStore();

  const discounts = getDiscounts(membershipData);
  const windowSlotPrice = bookingInputData?.time?.deliveryFee.amount ?? 0;

  const totalPrice = getTotalPrice({
    salesItems,
    coupons,
    windowSlotPrice,
    membershipData
  });

  return {
    totalPrice,
    discounts,
    coupons,
    salesItems
  };
};

export default useBookingSummaryProps;
