import { TZDate } from '@date-fns/tz';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

export const getBookingTimeWindowDisplayText = (timeWindow: AvailableBookingTimeWindow) => {
  const startDate = new TZDate(timeWindow.startPublic, timeWindow.timeZone);
  const endDate = new TZDate(timeWindow.endPublic, timeWindow.timeZone);
  const startDateFormatted = format(startDate, DateFormats.FULL_MONTH_YEAR_WITHOUT_COMMA);
  const startTimeFormatted = format(startDate, DateFormats.TIME);
  const endTimeFormatted = format(endDate, DateFormats.TIME);

  return `${startDateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
};
