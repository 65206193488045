import { Field, FieldProps } from 'formik';

import { NoddiSelect } from '../../Inputs';
import { NoddiSelectProps } from '../../Inputs/Select/NoddiSelect';
import { FormFieldErrorMessage } from '../FormFieldErrorMessage';

type NoddiFormSelectProps<Value> = {
  name: string;
} & NoddiSelectProps<Value>;
const NoddiFormSelect = <Value,>(props: NoddiFormSelectProps<Value>) => {
  const { name, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<'' | Value | undefined>) => (
        <div>
          <NoddiSelect
            {...field}
            {...rest}
            name={name}
            value={field.value ?? ''}
            error={Boolean(form.touched[name] && form.errors[name])}
            onChange={(event) => {
              form.setFieldValue(name, event.target.value);
            }}
          />
          <FormFieldErrorMessage name={name} component='div' />
        </div>
      )}
    </Field>
  );
};

export { NoddiFormSelect };
