import { commonTexts } from '../../../../commonTexts';
import { ErrorPageBase } from '../ErrorPageBase';

/**
 * The reason for this existence instead of using ErrorPage is that we can't
 * render any translations before the initProvider is ran, and this is a fallback
 * for the topmost sentry error boundary.
 */

// I guess we just want to use english here as default, as we are going international?
// fallback language should be in english not norwegian?
const NbFallback = () => {
  const { title, description, tryAgain } = commonTexts.errors;

  return (
    <div className='flex h-screen justify-center'>
      <ErrorPageBase
        title={title}
        description={description}
        buttonText={tryAgain}
        onButtonClick={window.location.reload}
      />
    </div>
  );
};

export { NbFallback };
