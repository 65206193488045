import { NoddiAsyncError } from 'noddi-async/src/types';
import { commonTexts } from '../../../commonTexts';
import { mapErrorCodeToUiMessage } from './ErrorMessage';
import { ErrorPageBase } from './ErrorPageBase';
import { useCaptureSentryException } from './utils';

export type ErrorPageProps = {
  errorTitle?: string;
  errorMessage?: string;
  apiError?: NoddiAsyncError | Array<NoddiAsyncError | null>;
  customCallback?: { ctaText: string; callback: () => void };
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
  translations?: {
    title: string;
    description: string;
    tryAgain: string;
  };
};

const ErrorPage = ({
  errorMessage,
  errorTitle,
  apiError,
  customCallback,
  translations,
  errorCodes
}: ErrorPageProps) => {
  const apiErrorMessage = apiError && errorCodes && mapErrorCodeToUiMessage({ error: apiError, errorCodes });

  const customErrorMessage = apiErrorMessage ?? errorMessage;

  function handleCustomCallback() {
    customCallback?.callback() ?? window.location.reload();
  }

  useCaptureSentryException(customErrorMessage, 'ErrorPage');

  const { title, description, tryAgain } = translations ?? commonTexts.errors;

  return (
    <ErrorPageBase
      title={errorTitle ?? title}
      description={customErrorMessage ?? description}
      buttonText={customCallback?.ctaText ?? tryAgain}
      onButtonClick={handleCustomCallback}
    />
  );
};

export { ErrorPage };
