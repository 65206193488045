import { Card, CardContent, CardHeader, CardProps, Divider, Stack, Typography } from '@mui/material';
import { ServerTypes } from 'noddi-async';
import { CarOrderLine, Currency } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { NoddiIcon } from '../../atoms';
import { commonTexts } from '../../commonTexts';

type AdminProps = {
  bookingId: number;
  userGroupId: number;
  cancelDialogIsOpen: boolean;
  setCancelDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  setBookingItemIdToDelete: Dispatch<SetStateAction<number | null>>;
};

type OrderSummaryProps = {
  MetaSection?: ReactElement;
  carItemLines: CarOrderLine[];
  discountLines: ServerTypes.BookingOrderLineProps[];
  otherOrderLines: ServerTypes.BookingOrderLineProps[];
  adminProps?: AdminProps;
  showHeader?: boolean;
  title?: string;
  translations?: {
    total: string;
    vat: string;
    yourOrder: string;
    discount: string;
    other: string;
  };
} & CardProps;

type OrderLineType = {
  title: string;
  price: number;
  currency: Currency;
  unableToComplete?: boolean;
};
type SubHeader = {
  title: string;
  icon: ReactElement;
  bookingItemId?: number | null;
  adminProps?: AdminProps;
};

type BookingItemSummary = {
  subHeader: SubHeader;
  orderLines: OrderLineType[];
};

const BookingItemHeader = ({ bookingItemId, title, icon, adminProps }: SubHeader) => {
  return adminProps && bookingItemId ? (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <div className='flex gap-2'>
        {icon}
        {title}
      </div>
      <NoddiIcon
        name='TrashBin'
        size='medium'
        className='cursor-pointer'
        onClick={() => {
          adminProps.setCancelDialogIsOpen(true);
          adminProps.setBookingItemIdToDelete(bookingItemId);
        }}
      />
    </Stack>
  ) : (
    <div className='flex gap-2'>
      {icon}
      {title}
    </div>
  );
};

const OrderLine = ({ title, price, currency, unableToComplete }: OrderLineType) => {
  const Header = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {title}
    </Typography>
  );

  const Value = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {formatCurrencyAmount(price, 2, currency)}
    </Typography>
  );
  return (
    <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
      <Header />
      <Value />
    </Stack>
  );
};
const OrderSummary = ({
  showHeader = true,
  carItemLines,
  MetaSection,
  discountLines,
  otherOrderLines,
  adminProps,
  title,
  translations,
  ...cardProps
}: OrderSummaryProps) => {
  const carOrders: BookingItemSummary[] = [];
  const discountOrderLines: OrderLineType[] = [];
  const others: BookingItemSummary[] = [];

  const texts = translations ?? commonTexts.orderSummary;
  carItemLines.forEach((car) => {
    const subHeader = {
      title: car.name,
      bookingItemId: car.bookingItemId,
      icon: <NoddiIcon name='Car' size='medium' />
    };
    const orderLines: OrderLineType[] = [];

    car.orderLines.forEach((orderLine) => {
      const price = orderLine.pricePerUnit * orderLine.quantity;
      if (price < 0) {
        discountOrderLines.push({
          title: orderLine.description,
          price,
          currency: orderLine.currency,
          unableToComplete: !!orderLine.unableToComplete
        });
      } else {
        orderLines.push({
          title: orderLine.description,
          price,
          currency: orderLine.currency,
          unableToComplete: !!orderLine.unableToComplete
        });
      }
    });
    carOrders.push({
      subHeader,
      orderLines
    });
  });

  if (otherOrderLines.length > 0) {
    const subHeader = {
      title: texts.other,
      icon: <NoddiIcon name='ShoppingCart' size='medium' />
    };
    const orderLines = otherOrderLines.map((orderLine) => {
      return {
        title: orderLine.description,
        id: orderLine.id,
        price: orderLine.pricePerUnit * orderLine.quantity,
        currency: orderLine.currency,
        unableToComplete: !!orderLine.unableToComplete
      };
    });
    others.push({
      subHeader,
      orderLines
    });
  }

  discountLines.forEach((orderLine) => {
    discountOrderLines.push({
      title: orderLine.description,
      price: orderLine.pricePerUnit * orderLine.quantity,
      currency: orderLine.currency,
      unableToComplete: !!orderLine.unableToComplete
    });
  });

  const allOrders = [...carOrders, ...others];

  if (discountOrderLines.length > 0) {
    allOrders.push({
      subHeader: {
        title: texts.discount,
        icon: <NoddiIcon name='Bill' size='medium' />
      },
      orderLines: discountOrderLines
    });
  }

  const allOrderLines = allOrders.flatMap((x) => x.orderLines);
  if (!allOrderLines || allOrderLines.length === 0) {
    throw new Error('No order lines found');
  }

  const currency = allOrderLines[0]?.currency;
  if (!currency) {
    throw new Error('No currency found');
  }

  allOrderLines.forEach((orderLine) => {
    if (orderLine.currency !== currency) {
      throw new Error('Currencies do not match');
    }
  });

  const totalPrice = allOrderLines.filter((x) => !x.unableToComplete).reduce((acc, x) => acc + x.price, 0);
  const mva = totalPrice * 0.2;

  return (
    <Card sx={{ width: '100%' }} {...cardProps}>
      {showHeader && (
        <>
          <CardHeader title={title ?? texts.yourOrder} />
          <Divider />
        </>
      )}
      <CardContent>
        {MetaSection && (
          <>
            {MetaSection}
            <Divider sx={{ marginY: '1rem' }} />
          </>
        )}
        {allOrders.map((order) => (
          <div key={order.subHeader.title}>
            <BookingItemHeader {...order.subHeader} adminProps={adminProps} />

            {order.orderLines.map((orderLine) => (
              <OrderLine {...orderLine} />
            ))}
            <Divider sx={{ marginY: '1rem' }} />
          </div>
        ))}
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <p>{texts.vat}</p>
          <p>{formatCurrencyAmount(mva, 2, currency)}</p>
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <p className='font-bold text-5'>{texts.total}</p>

          <p>{formatCurrencyAmount(totalPrice, 2, currency)}</p>
        </Stack>
      </CardContent>
    </Card>
  );
};

export { OrderSummary };
