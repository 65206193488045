import { AxiosResponse } from 'axios';

import {
  BookingDataHome,
  BookingDataHomeBase,
  BookingDetails,
  BookingInfo,
  BookingProps,
  BookingPropsListItem,
  CampaignPublic,
  CarDetails,
  CarDetailsProps,
  MembershipFromSlug,
  MembershipProgramProps,
  PaginatedInput,
  PaginatedResponse,
  PatchBookingParams,
  ReferralInfo,
  RegisteredCar,
  ServiceAreasDataFromCoordinate,
  TireHotelContract,
  TireHotelOptionsResponse,
  TireSetOffer,
  UserDataProps,
  UserGroupMemberNotificationSettings
} from '../types';
import { FeedbackData } from '../types/customerapp/feedback';
import { NAFDateOfBirthPayload, NAFMembershipNumberPayload } from '../types/customerapp/naf';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const CustomerURLKeys = {
  getUserBooking: 'getUserBooking',
  getBookingInfo: 'getBookingInfo',
  patchUserInfo: 'patchUserInfo',
  getCurrentEstimatedArrivalTime: 'getCurrentEstimatedArrivalTime',
  getCancelBooking: 'getCancelBooking',
  patchNotificationSettings: 'patchNotificationSettings',
  getServiceAreasFromCoordinate: 'getServiceAreasFromCoordinate',
  getUserGroupCarsForBooking: 'getUserGroupCarsForBooking',
  getUpcomingBookingsForUserGroup: 'getUpcomingBookingsForUserGroup',
  getCompletedBookingsForUserGroup: 'getCompletedBookingsForUserGroup',
  getPaginatedCarsForUserGroups: 'getPaginatedCarsForUserGroups',
  getUserGroupCar: 'getUserGroupCar',
  getCampaignFromSlug: 'getCampaignFromSlug',
  postActivateCampaign: 'postActivateCampaign',
  patchBooking: 'patchBooking',
  getBooking: 'getBooking',
  getMembershipPrograms: 'getMembershipPrograms',
  getTireHotelOptions: 'getTireHotelOptions',
  getTireHotelContracts: 'getTireHotelContracts',
  getReferrals: 'getReferrals',
  getBookings: 'getBookings',
  postImpersonateUser: 'postImpersonateUser',
  postFeedback: 'postFeedback',
  postDinteroSession: 'postDinteroSession',
  postNafMembership: 'postNafMembership',
  getIsNAFMember: 'getIsNAFMember',
  getMembershipFromSlug: 'getMembershipFromSlug',
  postActivateMembership: 'postActivateMembership',
  getVerifyMembershipCode: 'getVerifyMembershipCode',
  postConfirmTireQuoteOffer: 'postConfirmTireQuoteOffer',
  getTireSetOfferPageView: 'getTireSetOfferPageView',
  getTireSetOffersForUserGroup: 'getTireSetOffersForUserGroup'
} as const;

export type CustomerURLKeysType = keyof typeof CustomerURLKeys;

type InputDataOrVoid<Type extends CustomerURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends CustomerURLKeysType> = CustomerServerRequests[urlKey]['input'];

export const getCustomerUrls: {
  [key in CustomerURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getUserBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/get-booking-for-user-group/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUpcomingBookingsForUserGroup: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/upcoming-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCompletedBookingsForUserGroup: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/completed-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarsForUserGroups: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingInfo: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/info-from-slug/${slug}/`
      }),
    method: FetchMethods.get
  },
  patchUserInfo: {
    getUrl: ({ userId }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${userId}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCurrentEstimatedArrivalTime: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/info-from-slug/${slug}/current-estimated-arrival-window/`
      }),
    method: FetchMethods.get
  },
  getCancelBooking: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/cancel/`
      }),
    method: FetchMethods.get
  },
  getServiceAreasFromCoordinate: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/from-coordinate/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  patchNotificationSettings: {
    getUrl: ({ userGroupMemberId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-group-members/${userGroupMemberId}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getUserGroupCarsForBooking: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars-for-booking/`
      }),
    method: FetchMethods.get
  },
  getTireHotelOptions: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/tire-hotel-options/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupCar: {
    getUrl: ({ userGroupId, carId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars/${carId}/`
      }),
    method: FetchMethods.get
  },
  getCampaignFromSlug: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/from-slug/${slug}/`
      }),
    method: FetchMethods.get
  },
  postActivateCampaign: {
    getUrl({ campaignId }) {
      return getNoddiUrl({
        path: `${apiVersion}campaigns/${campaignId}/activate/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchBooking: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/`,
        queryParams
      }),
    getBody: (args) => {
      return args;
    },
    method: FetchMethods.patch
  },
  getBooking: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/`
      }),
    method: FetchMethods.get
  },
  getMembershipPrograms: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/get-memberships-for-user-group/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getTireHotelContracts: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/tire-hotel-contracts/`
      }),
    method: FetchMethods.get
  },
  getReferrals: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/get-referral-rewards/`
      }),
    method: FetchMethods.get
  },
  getBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/get-bookings-for-user-group/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postImpersonateUser: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/impersonate/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postFeedback: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-feedbacks/`
      }),

    getBody: (data) => data,
    method: FetchMethods.post
  },
  postDinteroSession: {
    getUrl: ({ orderId }) =>
      getNoddiUrl({
        path: `${apiVersion}orders/${orderId}/create-dintero-session/`
      }),

    method: FetchMethods.post
  },
  postNafMembership: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}memberships/add-naf-membership/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getIsNAFMember: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/is-naf-member/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getMembershipFromSlug: {
    getUrl: ({ slug, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postActivateMembership: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/activate/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getVerifyMembershipCode: {
    getUrl: ({ slug, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships/${slug}/check-code/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postConfirmTireQuoteOffer: {
    getUrl: ({ carTireQuoteId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${carTireQuoteId}/select/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTireSetOfferPageView: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${slug}/view/`
      }),
    method: FetchMethods.get
  },
  getTireSetOffersForUserGroup: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/car-tire-quotes/`
      }),
    method: FetchMethods.get
  }
} as const;

export interface CustomerServerRequests {
  getUserBooking: {
    input: { userGroupId: number; bookingId: string };
    output: BookingDetails;
  };
  getUpcomingBookingsForUserGroup: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<BookingDataHomeBase[]>;
  };
  getCompletedBookingsForUserGroup: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<BookingDataHome[]>;
  };
  getPaginatedCarsForUserGroups: {
    input: PaginatedInput & { userGroupId: number };
    output: PaginatedResponse<CarDetails[]>;
  };
  getBookingInfo: {
    input: { slug: string };
    output: BookingInfo;
  };
  patchUserInfo: {
    input: { firstName: string; lastName: string; userId: number };
    output: undefined;
  };
  getCurrentEstimatedArrivalTime: {
    input: { slug: string };
    output: { start?: string; end?: string };
  };
  getCancelBooking: {
    input: { bookingId: number };
    output: undefined;
  };
  patchNotificationSettings: {
    input: UserGroupMemberNotificationSettings & { userGroupMemberId?: number | null | undefined };
    output: undefined;
  };
  getServiceAreasFromCoordinate: {
    input: { lat?: number; lng?: number; userGroupId?: number };
    output: ServiceAreasDataFromCoordinate;
  };
  getUserGroupCarsForBooking: {
    input: { userGroupId: number | undefined | null };
    output: RegisteredCar[];
  };
  getUserGroupCar: {
    input: { userGroupId: number | undefined | null; carId: string };
    output: CarDetailsProps;
  };
  getCampaignFromSlug: {
    input: { slug?: string };
    output: CampaignPublic;
  };
  postActivateCampaign: {
    input: { campaignId: number; userGroupId: number };
    output: undefined;
  };
  patchBooking: {
    input: PatchBookingParams;
    output: AxiosResponse<BookingProps>;
  };
  getTireHotelOptions: {
    input: { vinNumbers: string[]; userGroupId?: number | null };
    output: TireHotelOptionsResponse[];
  };
  getBooking: {
    input: { bookingId: number };
    output: BookingProps;
  };
  getMembershipPrograms: {
    input: { userGroupId: number };
    output: MembershipProgramProps[];
  };
  getTireHotelContracts: {
    input: { userGroupId: number };
    output: TireHotelContract[];
  };
  getReferrals: {
    input: { userGroupId: number };
    output: ReferralInfo;
  };
  getBookings: {
    input: { userGroupId: number };
    output: BookingPropsListItem[];
  };
  postImpersonateUser: {
    input: {
      userId: number;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postFeedback: {
    input: FeedbackData;
    output: undefined;
  };
  postDinteroSession: {
    input: { orderId: number };
    output: AxiosResponse<{ sessionId: string; countryCode: 'NO' | 'SE' }>;
  };
  postNafMembership: {
    input: NAFDateOfBirthPayload | NAFMembershipNumberPayload;
    output: AxiosResponse<{ membershipAdded: boolean }>;
  };
  getIsNAFMember: {
    input: { userGroupId: number };
    output: { isMember: boolean };
  };
  getMembershipFromSlug: {
    input: { slug: string; userGroupId?: number };
    output: MembershipFromSlug;
  };
  postActivateMembership: {
    input: { userGroupId: number; authCode: string; slug: string };
    output: undefined;
  };
  getVerifyMembershipCode: {
    input: { slug: string; authCode: string };
    output: { isValid: boolean };
  };
  postConfirmTireQuoteOffer: {
    input: { comments: string; carTireQuoteId: number; selectedTireOption: { id: number; quantity: number } };
    output: undefined;
  };
  getTireSetOfferPageView: {
    input: { slug: string };
    output: undefined;
  };
  getTireSetOffersForUserGroup: {
    input: { userGroupId: number };
    output: TireSetOffer[];
  };
}
