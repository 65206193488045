import { URLKeys } from 'noddi-async';
import { AvailableSalesItem, SalesItemSlugs, wheelStorageOption } from 'noddi-async/src/types';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { useWheelStore } from '../../../../stores/WheelStore';
import { CarIdentifierWithIcon } from '../../CarIdentifierWithIcon';
import { SelectedCar } from '../../interfaces';
import { WheelStorageOption } from './WheelStorageOption';

type Props = {
  licensePlateNumber: SelectedCar['licensePlateNumber'];
  wheelStorageOptions: AvailableSalesItem[];
};

const mapSalesItemToStorageType = (salesItem: AvailableSalesItem) => {
  switch (salesItem.slug) {
    case SalesItemSlugs['nb-at-your-place-free']:
      return wheelStorageOption.customer;
    case SalesItemSlugs['nb-pick-up-at-another-address']:
      return wheelStorageOption.wheelStorage;
    default:
      return wheelStorageOption.customer;
  }
};

const WheelStorageSelector = ({ licensePlateNumber, wheelStorageOptions }: Props) => {
  const { bookingInputData } = useBookingContext();
  const { selectWheelStorageSalesItems } = useSelectSalesItem();

  const { selectedWheelStorageOptions, addSelectedWheelStorageOptions, removeSelectedWheelStorageOptions } =
    useWheelStore();

  const storageOptionsForVehicle = selectedWheelStorageOptions.find(
    (option) => option.licensePlateNumber === licensePlateNumber
  );
  const storageType = storageOptionsForVehicle?.storageType;

  const address = bookingInputData.address;
  const cars = bookingInputData.selectedCars;

  const car = cars.find((car) => car.licensePlateNumber === licensePlateNumber);
  if (!car || !address) {
    return <ErrorPageWithTranslations />;
  }

  // Sort the wheelStorageOptions to move the item with 'slug' equal to 'nb-at-your-place-free' to the first position
  wheelStorageOptions.sort((a, b) => {
    return a.slug === 'nb-at-your-place-free' ? -1 : b.slug === 'nb-at-your-place-free' ? 1 : 0;
  });
  return (
    <div className='flex flex-col gap-6'>
      <CarIdentifierWithIcon car={car} />

      <div className='flex flex-col gap-4'>
        {wheelStorageOptions.map((option) => {
          const salesItemStorageType = mapSalesItemToStorageType(option);
          return (
            <WheelStorageOption
              price={option.price}
              name={option.name}
              description={option.description}
              selectedStorageType={storageType}
              licensePlateNumber={licensePlateNumber}
              key={`${salesItemStorageType} ${option.id}`}
              currentStorageType={salesItemStorageType}
              handleClick={() => {
                // this selectSalesItem function also considers the case for deselection, so it is safe
                // to use before checking the type of storage

                selectWheelStorageSalesItems({
                  licensePlateNumber,
                  salesItem: option,
                  isAddon: false,
                  skipUpdateIncompatibles: true,
                  queryParams: { urlKey: URLKeys.getWheelStorageSalesItemsOptions, queryInput: undefined }
                });

                if (storageType === salesItemStorageType) {
                  return removeSelectedWheelStorageOptions(licensePlateNumber);
                }
                addSelectedWheelStorageOptions({
                  licensePlateNumber,
                  storageType: salesItemStorageType,
                  pickUpAddress: salesItemStorageType === wheelStorageOption.customer ? address : undefined
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WheelStorageSelector;
