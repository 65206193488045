import { t } from '@lingui/macro';
import { NoddiSelectableItem } from 'noddi-ui';

import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { useBookingRemoveCar } from '../../store';

type CarProps = {
  car: CarSpec;
};

export const Car = ({ car }: CarProps) => {
  const removeCar = useBookingRemoveCar();

  return (
    <NoddiSelectableItem
      description={car.licensePlateNumber}
      title={`${car.make} - ${car.model}`}
      iconName='Car'
      isSelected
      action={() => {
        removeCar(car);
      }}
      actionText={t`Remove`}
    />
  );
};
