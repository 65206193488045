import { useLocation, useNavigate } from 'react-router-dom';

import { navigateAndLog } from '../../BookingFlow/hooks/navigateAndLog';
import {
  addonsStepPath,
  addressStepPath,
  carStepPath,
  salesItemStepPath,
  tierHotelStepPath,
  timeWindowStepPath
} from '../../BookingFlowV2/components/bookingFlowPathsV2';
import { useIsStepValid } from './useStepCompletionV2';

export const useNavigateOnInvalidStep = () => {
  const navigate = useNavigate();

  const { pathname: activeStep } = useLocation();

  const { isAddressStepValid, isCarStepValid, isSalesItemStepValid, isTierHotelStepValid, isTimeWindowsStepValid } =
    useIsStepValid();
  /**
   * This function is used to validate the current step in a multi-step process.
   * It checks the active step and performs validation accordingly. If the validation fails, the function logs a validation message and navigates to the failed step.
   **/

  const navigateOnInvalidStep = () => {
    if (activeStep === addressStepPath) {
      return;
    }

    if (!isAddressStepValid) {
      navigateAndLog(activeStep, addressStepPath, navigate);
      return;
    }

    if (activeStep === carStepPath) {
      return;
    }

    if (!isCarStepValid) {
      navigateAndLog(activeStep, carStepPath, navigate);
      return;
    }

    if (activeStep === salesItemStepPath) {
      return;
    }

    if (!isSalesItemStepValid) {
      navigateAndLog(activeStep, salesItemStepPath, navigate);
      return;
    }

    if (activeStep === tierHotelStepPath) {
      return;
    }

    // This is also safe for the case when no TH items are selected, as the function will return true when no TH items are selected
    if (!isTierHotelStepValid) {
      navigateAndLog(activeStep, tierHotelStepPath, navigate);
      return;
    }

    if (activeStep === timeWindowStepPath || activeStep === addonsStepPath) {
      return;
    }

    if (!isTimeWindowsStepValid) {
      navigateAndLog(activeStep, timeWindowStepPath, navigate);
    }
  };

  return { navigateOnInvalidStep };
};
