import { WheelProps } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

import { cva } from 'class-variance-authority';
import { JSX } from 'react';
import { NoddiBasicCard } from '../../../../atoms';
import { commonTexts } from '../../../../commonTexts';
import {
  WheelPosition,
  calculateWheelStatus,
  findLatestWheelMeasurement,
  resolveWheelSetStatusLogo,
  wheelSetStatusToTranslation
} from '../helpers';
import { TireType, WheelSetStatus } from '../types';

type TireMeasurementProps = {
  HeaderElement?: JSX.Element;
  wheels: WheelProps[];
  tireType: TireType;
  translations?: {
    leftFront: string;
    rightFront: string;
    leftBack: string;
    rightBack: string;
    summerTires: string;
    winterTires: string;
    measuredAt: string;
    inAGoodCondition: string;
    missingMeasurements: string;
    soonWornOut: string;
    wornOut: string;
  };
};

const formatWithUnit = (value: number | undefined, unit: string) => {
  return value ? `${value} ${unit}` : '-';
};

const TireMeasurement = ({ wheels, tireType, translations, HeaderElement }: TireMeasurementProps) => {
  const safeGuardTranslations = translations ?? commonTexts.wheelMeasurements;
  const { leftBack, leftFront, rightBack, rightFront } = safeGuardTranslations;
  const tires = [
    { text: leftFront, position: WheelPosition.FrontLeft },
    { text: rightFront, position: WheelPosition.FrontRight },
    { text: leftBack, position: WheelPosition.RearLeft },
    { text: rightBack, position: WheelPosition.RearRight }
  ];
  return (
    <NoddiBasicCard className='flex flex-col gap-4'>
      {HeaderElement}
      <div className='grid grid-cols-2 gap-4'>
        {tires.map((tire) => (
          <CarDetailHeader
            key={tire.position}
            translations={safeGuardTranslations}
            positionText={tire.text}
            tireType={tireType}
            wheelPosition={tire.position}
            wheels={wheels}
          />
        ))}
      </div>
      <MeasuredAt wheels={wheels} translations={safeGuardTranslations} />
    </NoddiBasicCard>
  );
};

interface CarDetailHeaderProps {
  positionText: string;
  wheelPosition: WheelPosition;
  translations: {
    missingMeasurements: string;
    soonWornOut: string;
    wornOut: string;
    inAGoodCondition: string;
  };
  tireType: TireType;
  wheels: WheelProps[];
}

const borderStyles = cva('', {
  variants: {
    variant: {
      [WheelSetStatus.Satisfactory]: '',
      [WheelSetStatus.MissingMeasurements]: '',
      [WheelSetStatus.SoonWornOut]: 'border border-signal-warning',
      [WheelSetStatus.WornOut]: 'border border-signal-danger'
    }
  },
  defaultVariants: {
    variant: WheelSetStatus.Satisfactory
  }
});
const textStyles = cva('', {
  variants: {
    variant: {
      [WheelSetStatus.Satisfactory]: '',
      [WheelSetStatus.MissingMeasurements]: '',
      [WheelSetStatus.SoonWornOut]: 'text-signal-warning',
      [WheelSetStatus.WornOut]: 'text-signal-danger'
    }
  },
  defaultVariants: {
    variant: WheelSetStatus.Satisfactory
  }
});

function CarDetailHeader({ positionText, translations, wheels, wheelPosition, tireType }: CarDetailHeaderProps) {
  const wheelSetStatus = calculateWheelStatus(wheels, wheelPosition, tireType);
  const isSatisfactory = wheelSetStatus === WheelSetStatus.Satisfactory;
  const isMissingMeasurements = wheelSetStatus === WheelSetStatus.MissingMeasurements;

  const borderStyle = borderStyles({ variant: wheelSetStatus });
  const textStyle = textStyles({ variant: wheelSetStatus });

  return (
    <div className={`flex flex-col gap-1 rounded-lg bg-secondary-white p-2 ${borderStyle}`}>
      <p>{positionText}</p>

      <div className='flex items-center gap-2'>
        {/* only show icon if not satisfactory */}
        <div className='flex flex-col md:flex-row md:gap-1'>
          <p className='font-bold'>{formatWithUnit(findLatestWheelMeasurement(wheels, wheelPosition)?.value, 'mm')}</p>
          {!isSatisfactory && !isMissingMeasurements && (
            <div className='flex items-center gap-1'>
              {resolveWheelSetStatusLogo(wheelSetStatus)}
              <p className={textStyle}>{wheelSetStatusToTranslation(wheelSetStatus, translations)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface MeasuredAtInterface {
  wheels: WheelProps[];
  translations: {
    measuredAt: string;
  };
}
function MeasuredAt({ wheels, translations }: MeasuredAtInterface) {
  const measuredAt = findLatestWheelMeasurement(wheels, WheelPosition.RearRight)?.measuredAt;
  return measuredAt ? (
    <p className='mt-1 text-primary-darkPurple'>
      {translations.measuredAt + ` ${format(measuredAt, DateFormats.DOTTED_DATE)}`}
    </p>
  ) : null;
}
export { TireMeasurement };
