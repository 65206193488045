import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiLinearProgressLoader } from 'noddi-ui';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import AddMoreCarsView from './AddMoreCars';

const CarSelector = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  // need to use isLoading here, not isPending if not page doesn't work for not logged in users
  const {
    isLoading: IsRegisteredCarsLoading,
    data: registeredCars,
    error: registeredCarsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCarsForBooking,
    input: { userGroupId },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (IsRegisteredCarsLoading) {
    return <NoddiLinearProgressLoader />;
  }

  if (registeredCarsError) {
    return <ErrorPageWithTranslations apiError={registeredCarsError} />;
  }

  return <AddMoreCarsView registeredCars={registeredCars} />;
};

export default CarSelector;
