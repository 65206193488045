import { Trans } from '@lingui/macro';
import { useMediaQuery, useTheme } from '@mui/material';
import { NoddiButton, colors } from 'noddi-ui';
import { PropsWithChildren, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { cn } from 'noddi-ui/src/helpers/utils';
import routes from '../../../appRoutes';

import PublicServiceAnnouncement from '../../BookingFlow/PublicServiceAnnouncement';
import { useScrollToTop } from '../../BookingFlow/hooks/useScrollToTop';
import { FixedBottomBarV2 } from './FixedBottomBarV2';

export const BookingContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding-top: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 30px;

  //Because of the shopping
  @media (max-width: 640px) {
    padding-bottom: 50px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 92vh;

  @media (min-height: 1000px) {
    min-height: 100vh;
  }
`;

export const InnerContainer = styled.div<{ whiteBg?: boolean; isTimePickerStepAndMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${(p) => (p.whiteBg ? colors.primary.white : 'inherit')};

  ${(p) =>
    p.isTimePickerStepAndMobile &&
    `
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-top: 16px;
    background:transparent;
  `}
`;

interface ScreenProps {
  title: string;
  description?: string;
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
  CustomBottomBar?: ReactNode;
  noMaxwidth?: boolean;
  showSelectedCars?: boolean;
}

function useIsMobileViewInBookingFlow() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}

export const BookingScreenV2 = ({
  title,
  children,
  description,
  onNextClicked,
  disableNextButton,
  CustomBottomBar,
  hideNextButton,
  hideShoppingCart,
  showSelectedCars,
  noMaxwidth
}: PropsWithChildren<ScreenProps>) => {
  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname === routes.login.getPath();
  const isAddressStep = pathname.includes(routes.newAddressV2.getBasePath());
  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());

  const showBackButton = !isLoginPage && !isAddressStep;

  useScrollToTop();

  return (
    <TopContainer>
      <InnerContainer
        whiteBg={isTimePickerStep && isMobileViewInBookingFlow}
        isTimePickerStepAndMobile={isTimePickerStep && isMobileViewInBookingFlow}
      >
        <BookingContainer>
          <div className={cn('mb-17 flex-col px-6 lg:px-0', !noMaxwidth && 'mx-auto w-full max-w-2xl')}>
            <PublicServiceAnnouncement />
            <div className='flex-col'>
              <div className='mb-5 flex flex-col gap-2 sm:mb-10'>
                <div className='flex flex-col'>
                  {showBackButton && (
                    <NoddiButton
                      startIcon='ArrowLeft'
                      onClick={() => {
                        navigate(-1);
                      }}
                      variant='ghost'
                      className='-ml-3.5'
                    >
                      <Trans>Back</Trans>
                    </NoddiButton>
                  )}
                  <h1 className='text-7 sm:text-13'>{title}</h1>
                </div>
                {description && <p className='ml-1 text-systemColors-grey lg:ml-2'>{description}</p>}
              </div>
              {children}
            </div>
          </div>
        </BookingContainer>
      </InnerContainer>
      {CustomBottomBar ?? (
        <FixedBottomBarV2
          hideNextButton={hideNextButton}
          disableNextButton={disableNextButton}
          hideShoppingCart={hideShoppingCart}
          onNextClicked={onNextClicked}
          showSelectedCars={showSelectedCars}
        />
      )}
    </TopContainer>
  );
};
