import { NoddiAsyncError, UserDataProps } from 'noddi-async/src/types';
import { JSX, createContext } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface AuthContextType {
  userData: UserDataProps | null;
  superUser: UserDataProps | null;
  updateUserData: (value: UserDataProps) => void;
  impersonatedUser: UserDataProps | null;
  setImpersonatedUser: (value: UserDataProps) => void;
  updateImpersonatedData: (value: UserDataProps) => void;
  logout: () => Promise<JSX.Element>;
  getCurrentUserGroupId: () => number | undefined;
  numberOfMembersInCurrentUserGroup: number | undefined;
  getCurrentUserGroupMemberId: () => number | undefined;
  currentUserGroupId?: number;
  isLoggedIn: boolean;
  tokenLogin: (token: string) => Promise<any>;
  getToken: () => string | null;
  getImpersonatedToken: () => string | null;
  canActivateCouponsForNewUsersOnly: boolean;
  isSuperUser: boolean;
  clearImpersonatedUser: () => void;
  isTokenLoginLoading: boolean;
  isTokenLoginError?: boolean;
  tokenLoginError: NoddiAsyncError | null;
  loginUser: (data: UserDataProps) => void;
  serviceDepartmentId?: number | undefined;
  hasCoupons: boolean | undefined;
  hasMemberships: boolean | undefined;
  hasTireHotel: boolean | undefined;
  tokenLoginOfUsers: () => Promise<any>;
  couldBeLoggedIn: boolean;
  isTokenLoadingOnMount: boolean;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
