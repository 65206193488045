import { Trans } from '@lingui/macro';
import { FixedBottomBarContainer, NoddiButton, useIsWithinBreakpoint } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import HelpScoutChat from '../../components/BookingFlow/HelpScoutChat';
import { SelectedCars } from './SelectedCars';
import ShoppingCart from './Steps/Summary/ShoppingCart';

interface ScreenProps {
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
  showSelectedCars?: boolean;
}

const FixedBottomBar = ({
  onNextClicked,
  disableNextButton,
  hideNextButton,
  hideShoppingCart,
  showSelectedCars
}: PropsWithChildren<ScreenProps>) => {
  const { pathname } = useLocation();
  const isXs = useIsWithinBreakpoint('xs');

  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());

  if (isTimePickerStep) {
    return null;
  }

  const showShoppingCart = !hideShoppingCart;

  return (
    <FixedBottomBarContainer>
      <div className='mr-2'>
        {(showShoppingCart || showSelectedCars) && isXs ? (
          <HelpScoutChat size='small' />
        ) : (
          <HelpScoutChat size='medium' />
        )}
      </div>
      <div className='flex w-full items-center justify-between gap-3'>
        {showShoppingCart && isXs && (
          <div>
            <ShoppingCart />
          </div>
        )}
        {showSelectedCars && isXs && (
          <div>
            <SelectedCars />
          </div>
        )}

        <div className='ml-auto flex gap-3'>
          {showShoppingCart && !isXs && <ShoppingCart />}
          {showSelectedCars && !isXs && <SelectedCars />}

          {!hideNextButton && (
            <NoddiButton onClick={onNextClicked} disabled={disableNextButton} endIcon='ArrowRight'>
              <Trans>Continue</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
    </FixedBottomBarContainer>
  );
};

export default FixedBottomBar;
