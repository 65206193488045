import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen } from 'noddi-ui';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useFetchAddons from '../../../../hooks/ApiHooks/useFetchAddons';
import BookingScreen from '../../BookingScreen';
import { addonsStepPath, timeSlotStepPath, wheelStorageStepPath } from '../../bookingFlowPaths';
import useHasSelectedWheelStorage from '../../hooks/useHasSelectedWheelStorage';
import { useStepCompletionValidation } from '../../hooks/useStepCompletionValidation';
import SalesItemCar from './SalesItemCar';

const SalesItems = () => {
  const navigate = useNavigate();
  const { isSalesItemStepValid } = useStepCompletionValidation();
  const hasSelectedWheelStorage = useHasSelectedWheelStorage();

  const { refetch, isLoading: fetchingAddons } = useFetchAddons();
  const [isLoading, setIsLoading] = useState(false);

  const navigateToNextStep = async () => {
    const { data: addons } = await refetch();

    if (hasSelectedWheelStorage) {
      return navigate(wheelStorageStepPath);
    }

    if (addons?.length) {
      return navigate(addonsStepPath);
    }

    return navigate(timeSlotStepPath);
  };

  async function onNextButtonClicked() {
    setIsLoading(true);
    await navigateToNextStep();
    setIsLoading(false);
  }

  return (
    <BookingScreen
      onNextClicked={onNextButtonClicked}
      disableNextButton={!isSalesItemStepValid}
      title={t`What can we help you with?`}
    >
      <SalesItemsSelector isLoading={fetchingAddons || isLoading} />
    </BookingScreen>
  );
};

export const SalesItemsSelector = ({ isLoading }: { isLoading: boolean }) => {
  const { bookingInputData } = useBookingContext();
  const [showErrorPage, setShowErrorPage] = useState(false);

  const inputs = bookingInputData.selectedCars.map((car) => ({
    type: URLKeys.getAvailableSalesItemsForBooking,
    input: {
      carSizeId: car.carSizeId,
      licensePlateNumber: car.licensePlateNumber,
      latitude: bookingInputData.address!.latitude,
      longitude: bookingInputData.address!.longitude
    },
    queryConfig: {
      gcTime: Infinity,
      staleTime: Infinity
    }
  }));

  const results = noddiAsync.useGetAll(inputs);

  const isSalesItemsLoading = results.map((result) => result.isPending).some((loading) => loading);

  if (showErrorPage) {
    return <ErrorPageWithTranslations />;
  }

  if (isLoading || isSalesItemsLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className='flex flex-col gap-8'>
      {bookingInputData.selectedCars.map((car, index) => {
        const salesItemsData = results[index]?.data;
        if (!salesItemsData) {
          setShowErrorPage(true);
          return null;
        }

        return (
          <SalesItemCar
            key={car.licensePlateNumber}
            car={car}
            hasActiveTireHotelContract={salesItemsData.meta.hasActiveTireHotelContract}
            availableSalesItems={salesItemsData.salesItems}
          />
        );
      })}
    </div>
  );
};

export default SalesItems;
