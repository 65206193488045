import { TireHotelContract } from 'noddi-async/src/types';
import { createContext, Dispatch, SetStateAction } from 'react';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

interface TireHotelBookingType {
  selectedTireHotelContracts: TireHotelContract[];
  dialogOpen: boolean;
  currentStep: TireStorageBookingSteps;
  setSelectedTireHotelContracts: Dispatch<SetStateAction<TireHotelContract[]>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<TireStorageBookingSteps>>;
  resetTireHotelData: () => void;
}

export const TireHotelContext = createContext<TireHotelBookingType>({} as TireHotelBookingType);
