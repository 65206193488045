import { t } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiChip, NoddiIcon, useIsMobile } from 'noddi-ui';
import { DateFormats, datesSortCompare, format, formatCurrencyAmount } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

import { UserGroupBooking } from 'noddi-async/src/types';
import routes from '../../appRoutes';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import NoBookingsCard from '../../components/Home/NoUpcomingBookingsCard';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

export const Bookings = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();
  const {
    isPending,
    data: bookings,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupBookings,
    input: { userGroupId: userGroupId as number }
  });

  if (isPending) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorPageWithTranslations apiError={error} />;
  }

  if (bookings.length === 0) {
    return (
      <ContentWrapper className='text-primary-darkPurple' title={t`Your bookings`}>
        <div className='md:w-1/2'>
          <NoBookingsCard />
        </div>
      </ContentWrapper>
    );
  }

  function goToBookingDetails(bookingId: number) {
    navigate(routes.myBookingDetails.getPath({ id: bookingId }));
  }

  const bookingsSortedByUnpaidFirst = bookings.sort((a, b) => {
    // First, prioritize bookings that are completed but unpaid
    if (a.completedAt && !a.isPaid && !(b.completedAt && !b.isPaid)) {
      return -1;
    }
    if (b.completedAt && !b.isPaid && !(a.completedAt && !a.isPaid)) {
      return 1;
    }

    return datesSortCompare(a.date, b.date, 'desc');
  });

  const getPaymentStatusChip = ({ booking }: { booking: UserGroupBooking }) => {
    if (booking.isPaid) {
      return <NoddiChip variant='success' label={t`Paid`} />;
    } else if (booking.isInvoiced) {
      return <NoddiChip variant='warning' label={t`Invoiced`} />;
    } else {
      return <NoddiChip variant='destructive' label={t`Unpaid`} />;
    }
  };

  return (
    <ContentWrapper title={t`Your bookings`} showNewBookingButton>
      <div className='flex w-full flex-col gap-4'>
        {bookingsSortedByUnpaidFirst.map((booking) => {
          const orderLinesDescriptionString = booking.orderLineDescriptions
            .map((description) => description)
            .join(', ');
          return (
            <button
              key={booking.id}
              className='flex min-w-75 cursor-pointer items-center justify-between rounded-2xl bg-primary-white px-5 py-4 text-primary-darkPurple shadow-md'
              onClick={() => goToBookingDetails(booking.id)}
            >
              <div className='flex gap-5'>
                {!isMobile && (
                  <div className='rounded-lg bg-primary-pink/80'>
                    <div className='flex size-20 flex-col items-center justify-center'>
                      <p>{format(booking.date, DateFormats.SHORT_DAY_SHORT_MONTH)}</p>
                      <p className='text-3'>{format(booking.date, DateFormats.FULL_YEAR)}</p>
                    </div>
                  </div>
                )}

                <div className='mr-2 flex flex-col justify-center text-5'>
                  <p className='p-0 text-left text-5'>{orderLinesDescriptionString}</p>
                  {isMobile && (
                    <div className='flex items-center gap-1'>
                      <NoddiIcon name='Calendar' size='small' className='pl-1 sm:pl-0' />
                      <p className='text-3'>{format(booking.date, DateFormats.DAY_MONTH)}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='flex items-center gap-1'>
                {booking.completedAt ? (
                  getPaymentStatusChip({ booking })
                ) : (
                  <p>{formatCurrencyAmount(booking.price, 0)}</p>
                )}
                <NoddiIcon name='AltArrowRight' size='medium' />
              </div>
            </button>
          );
        })}
      </div>
    </ContentWrapper>
  );
};
