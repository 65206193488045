import { captureException } from '@sentry/react';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { useEffect } from 'react';

const handleStringError = (error: string, title?: string) => {
  const errorToSend = new Error(error);
  errorToSend.name = title ?? 'UnknownError';
  captureException(errorToSend);
};

const handleAxiosError = (error: NoddiAsyncError) => {
  const errorMessage = getErrorDetails(error);
  const errorCode = error?.status ?? 'UnknownCode';
  const errorInstance = new Error(errorMessage);
  errorInstance.name = `Axios Error: ${errorCode}`;
  captureException(errorInstance);
};

export const useCaptureSentryException = (
  error?: NoddiAsyncError | (NoddiAsyncError | null)[] | string,
  title?: string
) => {
  useEffect(() => {
    const processError = (error: NoddiAsyncError | null | string) => {
      if (!error) {
        return;
      }
      if (typeof error === 'string') {
        handleStringError(error, title);
      } else if (error.isAxiosError) {
        handleAxiosError(error);
      } else {
        captureException(error);
      }
    };

    if (!error) {
      return;
    }

    if (Array.isArray(error)) {
      error.forEach(processError);
    } else {
      processError(error);
    }
  }, [error, title]);
};

const getErrorDetails = (error: NoddiAsyncError): string =>
  error.response?.data.errors?.map((e) => e.detail).join(', ') ?? error.message;

export const getRawNoddiApiErrors = (error: NoddiAsyncError | (NoddiAsyncError | null)[]): string => {
  const nonNullErrors = Array.isArray(error) ? error.filter((e): e is NoddiAsyncError => e != null) : [error];

  return nonNullErrors.map(getErrorDetails).join(', ');
};
