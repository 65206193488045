import { t } from '@lingui/macro';
import { NoddiSelectableItem } from 'noddi-ui';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { SelectedCar } from '../../interfaces';

type CarListItemProps = {
  selectedCar: SelectedCar;
};

const CarListItem = ({ selectedCar }: CarListItemProps) => {
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  const { deselectAllSalesItemsForCar } = useSelectSalesItem();

  return (
    <NoddiSelectableItem
      description={selectedCar?.licensePlateNumber}
      title={`${selectedCar.carName}`}
      iconName='Car'
      isSelected
      action={() => {
        updateBookingInputData({
          selectedCars: bookingInputData.selectedCars.filter(
            (x) => x.licensePlateNumber !== selectedCar.licensePlateNumber
          )
        });

        deselectAllSalesItemsForCar(selectedCar.licensePlateNumber);
      }}
      actionText={t`Remove`}
    />
  );
};

export default CarListItem;
