import * as Sentry from '@sentry/react';
import { LoadingScreen } from 'noddi-ui';
import { storage } from 'noddi-util';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from './LanguageContext';

type LanguageProviderProps = {
  children: React.ReactNode;
  defaultLocale: LanguageCodeType;
  dynamicActivateLocale?: (locale: LanguageCodeType) => Promise<void>;
};

export const LanguageCode = {
  NORWEGIAN: 'nb',
  SWEDISH: 'se',
  ENGLISH: 'en'
} as const;

export type LanguageCodeType = (typeof LanguageCode)[keyof typeof LanguageCode];

export const localeStorageLocaleKey = 'locale';

export const LanguageProvider = ({ children, defaultLocale, dynamicActivateLocale }: LanguageProviderProps) => {
  const [currentLanguage, setCurrentLanguage] = useState<LanguageCodeType>(() => {
    return (storage.local.getItem<LanguageCodeType>(localeStorageLocaleKey) as LanguageCodeType) || defaultLocale;
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (dynamicActivateLocale) {
      setLoading(true);
      dynamicActivateLocale(currentLanguage)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setLanguageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  useEffect(() => {
    setLanguageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the language tag on the <html> element and sentry
  const setLanguageData = () => {
    document.documentElement.setAttribute('lang', currentLanguage);
    Sentry.setTag('language', currentLanguage);
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {loading ? <LoadingScreen /> : children}
    </LanguageContext.Provider>
  );
};

export function useLanguageContext() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageProvider');
  }
  return context;
}
